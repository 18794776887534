export const Visitors = ({ size = 64, theme }) =>
  theme === "light" ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <g clipPath="url(#clip0_3259_4990)"> */}
      <path
        opacity="0.5"
        d="M41.9634 16.6522C47.0046 11.6915 55.1779 11.6915 60.2191 16.6522C65.2603 21.6128 65.2603 29.6557 60.2191 34.6164L34.1395 60.2795C29.0983 65.2402 20.9249 65.2402 15.8837 60.2795C10.8426 55.3188 10.8426 47.276 15.8837 42.3153L41.9634 16.6522Z"
        fill="url(#paint0_linear_3259_4990)"
      />
      <path
        opacity="0.35"
        d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1574 8.68118 53.1574 16.724 48.1163 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
        fill="url(#paint1_linear_3259_4990)"
      />
      <path
        d="M8.94141 17.8824C8.94141 14.7636 11.4697 12.2354 14.5885 12.2354H49.412C52.5308 12.2354 55.0591 14.7636 55.0591 17.8824V46.1177C55.0591 49.2365 52.5308 51.7648 49.412 51.7648H14.5885C11.4697 51.7648 8.94141 49.2365 8.94141 46.1177V17.8824Z"
        fill="#0255FF"
      />
      <path
        d="M8.94141 17.8824C8.94141 14.7636 11.4697 12.2354 14.5885 12.2354H49.412C52.5308 12.2354 55.059 14.7636 55.059 17.8824V28.7059H8.94141V17.8824Z"
        fill="#2E72FF"
      />
      <path
        d="M19.7652 7.05884C20.8048 7.05884 21.6475 7.9016 21.6475 8.94119L21.6475 16.4706C21.6475 17.5102 20.8048 18.353 19.7652 18.353C18.7256 18.353 17.8828 17.5102 17.8828 16.4706L17.8828 8.94119C17.8828 7.9016 18.7256 7.05884 19.7652 7.05884Z"
        fill="#004DEC"
      />
      <path
        d="M44.7066 7.05884C45.7462 7.05884 46.5889 7.9016 46.5889 8.94119L46.5889 16.4706C46.5889 17.5102 45.7462 18.353 44.7066 18.353C43.667 18.353 42.8242 17.5102 42.8242 16.4706L42.8242 8.94119C42.8242 7.9016 43.667 7.05884 44.7066 7.05884Z"
        fill="#004DEC"
      />
      <path
        d="M19.7652 5.64697C20.8048 5.64697 21.6475 6.48973 21.6475 7.52933L21.6475 15.0587C21.6475 16.0983 20.8048 16.9411 19.7652 16.9411C18.7256 16.9411 17.8828 16.0983 17.8828 15.0587L17.8828 7.52933C17.8828 6.48973 18.7256 5.64697 19.7652 5.64697Z"
        fill="#568DFF"
      />
      <path
        d="M44.7066 5.64697C45.7462 5.64697 46.5889 6.48973 46.5889 7.52933L46.5889 15.0587C46.5889 16.0983 45.7462 16.9411 44.7066 16.9411C43.667 16.9411 42.8242 16.0983 42.8242 15.0587L42.8242 7.52933C42.8242 6.48973 43.667 5.64697 44.7066 5.64697Z"
        fill="#568DFF"
      />
      <path
        d="M29.0893 36.7058H26.5143L22.4785 26.0442H25.3698L27.8395 33.6037H27.8997L30.3543 26.0442H33.2004L29.0893 36.7058Z"
        fill="white"
      />
      <path
        d="M39.098 31.4202C38.8973 31.2294 38.6613 31.0688 38.3903 30.9383C38.1192 30.8078 37.8331 30.7425 37.5319 30.7425C37.301 30.7425 37.0852 30.7877 36.8844 30.8781C36.6937 30.9684 36.5983 31.124 36.5983 31.3449C36.5983 31.5557 36.7037 31.7063 36.9145 31.7967C37.1354 31.887 37.4868 31.9874 37.9686 32.0978C38.2497 32.1581 38.5308 32.2434 38.8119 32.3538C39.1031 32.4643 39.3641 32.6098 39.595 32.7905C39.8259 32.9712 40.0116 33.1921 40.1522 33.4531C40.2927 33.7141 40.363 34.0254 40.363 34.3868C40.363 34.8586 40.2676 35.2602 40.0769 35.5915C39.8861 35.9127 39.6351 36.1738 39.3239 36.3745C39.0228 36.5753 38.6814 36.7209 38.2999 36.8112C37.9184 36.9016 37.5369 36.9468 37.1555 36.9468C36.5431 36.9468 35.9407 36.8514 35.3484 36.6607C34.7661 36.4599 34.2792 36.1687 33.8877 35.7872L35.2731 34.3265C35.494 34.5675 35.765 34.7683 36.0863 34.9289C36.4075 35.0895 36.7489 35.1698 37.1103 35.1698C37.3111 35.1698 37.5068 35.1247 37.6976 35.0343C37.8984 34.9339 37.9988 34.7632 37.9988 34.5223C37.9988 34.2914 37.8783 34.1207 37.6373 34.0103C37.3964 33.8999 37.0199 33.7844 36.5079 33.664C36.2469 33.6037 35.9859 33.5234 35.7249 33.423C35.4638 33.3226 35.2279 33.1871 35.0171 33.0164C34.8163 32.8458 34.6507 32.6349 34.5202 32.384C34.3897 32.133 34.3244 31.8318 34.3244 31.4804C34.3244 31.0287 34.4198 30.6472 34.6105 30.336C34.8013 30.0147 35.0472 29.7537 35.3484 29.5529C35.6496 29.3521 35.9809 29.2065 36.3423 29.1162C36.7137 29.0158 37.0802 28.9656 37.4416 28.9656C38.0038 28.9656 38.5509 29.056 39.083 29.2367C39.6251 29.4073 40.0819 29.6683 40.4533 30.0197L39.098 31.4202Z"
        fill="white"
      />
      {/* </g> */}
      <defs>
        <linearGradient
          id="paint0_linear_3259_4990"
          x1="15.8838"
          y1="60.2794"
          x2="59.5055"
          y2="15.9497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0255FF" />
          <stop offset="1" stopColor="#AEC9FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3259_4990"
          x1="48.1162"
          y1="3.72065"
          x2="4.49449"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0255FF" />
          <stop offset="1" stopColor="#AEC9FF" />
        </linearGradient>
        <clipPath id="clip0_3259_4990">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_167)">
        <path
          opacity="0.9"
          d="M41.9634 16.6522C47.0046 11.6915 55.1779 11.6915 60.2191 16.6522C65.2603 21.6128 65.2603 29.6557 60.2191 34.6164L34.1395 60.2795C29.0983 65.2402 20.9249 65.2402 15.8837 60.2795C10.8426 55.3188 10.8426 47.276 15.8837 42.3153L41.9634 16.6522Z"
          fill="url(#paint0_linear_3_167)"
        />
        <path
          opacity="0.8"
          d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1574 8.68118 53.1574 16.724 48.1163 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
          fill="url(#paint1_linear_3_167)"
        />
        <path
          d="M8.94141 17.8823C8.94141 14.7635 11.4697 12.2353 14.5885 12.2353H49.412C52.5308 12.2353 55.0591 14.7636 55.0591 17.8823V46.1176C55.0591 49.2364 52.5308 51.7647 49.412 51.7647H14.5885C11.4697 51.7647 8.94141 49.2364 8.94141 46.1176V17.8823Z"
          fill="#0255FF"
        />
        <path
          d="M8.94141 17.8823C8.94141 14.7635 11.4697 12.2353 14.5885 12.2353H49.412C52.5308 12.2353 55.059 14.7635 55.059 17.8823V28.7059H8.94141V17.8823Z"
          fill="#2E72FF"
        />
        <path
          d="M19.7652 7.05879C20.8048 7.05879 21.6475 7.90155 21.6475 8.94115L21.6475 16.4706C21.6475 17.5102 20.8048 18.3529 19.7652 18.3529C18.7256 18.3529 17.8828 17.5102 17.8828 16.4706L17.8828 8.94115C17.8828 7.90155 18.7256 7.05879 19.7652 7.05879Z"
          fill="#004DEC"
        />
        <path
          d="M44.7065 7.05878C45.7461 7.05878 46.5889 7.90154 46.5889 8.94113L46.5889 16.4705C46.5889 17.5101 45.7461 18.3529 44.7065 18.3529C43.6669 18.3529 42.8242 17.5101 42.8242 16.4705L42.8242 8.94113C42.8242 7.90154 43.6669 7.05878 44.7065 7.05878Z"
          fill="#004DEC"
        />
        <path
          d="M19.7651 5.64705C20.8047 5.64705 21.6475 6.48981 21.6475 7.5294L21.6475 15.0588C21.6475 16.0984 20.8047 16.9412 19.7651 16.9412C18.7255 16.9412 17.8828 16.0984 17.8828 15.0588L17.8828 7.5294C17.8828 6.48981 18.7255 5.64705 19.7651 5.64705Z"
          fill="#568DFF"
        />
        <path
          d="M44.7065 5.64702C45.7461 5.64702 46.5889 6.48978 46.5889 7.52937L46.5889 15.0588C46.5889 16.0984 45.7461 16.9411 44.7065 16.9411C43.6669 16.9411 42.8242 16.0984 42.8242 15.0588L42.8242 7.52937C42.8242 6.48978 43.6669 5.64702 44.7065 5.64702Z"
          fill="#568DFF"
        />
        <path
          d="M29.0893 36.7059H26.5143L22.4785 26.0442H25.3698L27.8395 33.6037H27.8997L30.3543 26.0442H33.2004L29.0893 36.7059Z"
          fill="white"
        />
        <path
          d="M39.098 31.4202C38.8973 31.2295 38.6613 31.0688 38.3903 30.9383C38.1192 30.8078 37.8331 30.7426 37.5319 30.7426C37.301 30.7426 37.0852 30.7877 36.8844 30.8781C36.6937 30.9685 36.5983 31.1241 36.5983 31.3449C36.5983 31.5557 36.7037 31.7063 36.9145 31.7967C37.1354 31.887 37.4868 31.9874 37.9686 32.0979C38.2497 32.1581 38.5308 32.2434 38.8119 32.3539C39.1031 32.4643 39.3641 32.6099 39.595 32.7906C39.8259 32.9713 40.0116 33.1921 40.1522 33.4532C40.2927 33.7142 40.363 34.0254 40.363 34.3868C40.363 34.8587 40.2676 35.2602 40.0769 35.5915C39.8861 35.9128 39.6351 36.1738 39.3239 36.3746C39.0228 36.5754 38.6814 36.7209 38.2999 36.8113C37.9184 36.9016 37.5369 36.9468 37.1555 36.9468C36.5431 36.9468 35.9407 36.8514 35.3484 36.6607C34.7661 36.4599 34.2792 36.1688 33.8877 35.7873L35.2731 34.3266C35.494 34.5675 35.765 34.7683 36.0863 34.9289C36.4075 35.0896 36.7489 35.1699 37.1103 35.1699C37.3111 35.1699 37.5068 35.1247 37.6976 35.0343C37.8984 34.9339 37.9988 34.7633 37.9988 34.5223C37.9988 34.2914 37.8783 34.1208 37.6373 34.0103C37.3964 33.8999 37.0199 33.7845 36.5079 33.664C36.2469 33.6037 35.9859 33.5234 35.7249 33.423C35.4638 33.3227 35.2279 33.1871 35.0171 33.0165C34.8163 32.8458 34.6507 32.635 34.5202 32.384C34.3897 32.133 34.3244 31.8318 34.3244 31.4805C34.3244 31.0287 34.4198 30.6472 34.6105 30.336C34.8013 30.0147 35.0472 29.7537 35.3484 29.5529C35.6496 29.3521 35.9809 29.2066 36.3423 29.1162C36.7137 29.0158 37.0802 28.9656 37.4416 28.9656C38.0038 28.9656 38.5509 29.056 39.083 29.2367C39.6251 29.4074 40.0819 29.6684 40.4533 30.0197L39.098 31.4202Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3_167"
          x1="15.8838"
          y1="60.2794"
          x2="59.5055"
          y2="15.9497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0255FF" stopOpacity="0.7" />
          <stop offset="1" stopColor="#0255FF" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_167"
          x1="48.1162"
          y1="3.72065"
          x2="4.49449"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0255FF" stopOpacity="0.9" />
          <stop offset="1" stopColor="#0255FF" stopOpacity="0.2" />
        </linearGradient>
        <clipPath id="clip0_3_167">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
