import api from "./clients/api";

import { OrganizationDetails } from "../types/Organization";

export const getOrganizationsDetails = async () => {
  const { data } = await api.get<OrganizationDetails>("/organization/details");

  return data;
};

export default {
  getOrganizationsDetails,
};
