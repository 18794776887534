import { useState } from "react";
import type { FC } from "react";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AppBar, Box, Button, Divider, IconButton, Toolbar } from "@material-ui/core";
import { LanguagePopover } from "../../components/molecules/LanguagePopover";
import { AccountPopover } from "../../components/organisms/AccountPopover";
import { NavbarMenu } from "../../components/organisms/NavbarMenu";
import { AppDialog } from "../../components/organisms/AppDialog";

import type { Theme } from "@material-ui/core";

import { useSettings } from "../../contexts/SettingsContext";

import { ChevronDown as ChevronDownIcon } from "../../icons/chevron-down";
import { Moon as MoonIcon } from "../../icons/moon";
import { Sun as SunIcon } from "../../icons/sun";

import { Apps } from "@material-ui/icons";

import { useDialog } from "../../hooks/use-dialog";

import { appInfo } from "../../config";
import { SettingsPopover } from "../molecules/SettingsPopover";
import useStore from "src/hooks/useStore";

import { observer } from "mobx-react-lite";

const Navbar: FC = () => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { i18n, t } = useTranslation();

  const { user } = useStore();

  const { settings, saveSettings } = useSettings();
  const [openMenu, setOpenMenu] = useState(false);
  const [darkMode, setDarkMode] = useState(settings.theme === "dark");
  const [appDialog, handleOpenAppDialog, handleCloseAppDialog] = useDialog();

  const handleLanguageChange = (language: "en" | "pl"): void => {
    i18n.changeLanguage(language);
    saveSettings({
      ...settings,
      language,
    });
    toast.success(t("Language changed"));
  };

  const handleSwitchTheme = (): void => {
    saveSettings({
      ...settings,
      theme: settings.theme === "light" ? "dark" : "light",
    });

    setDarkMode(settings.theme === "light");
  };

  return (
    <AppBar elevation={0} sx={{ backgroundColor: "#1e212a" }}>
      <Toolbar
        disableGutters
        sx={{
          alignItems: "center",
          display: "flex",
          minHeight: 64,

          pr: 2,
          py: 1,
        }}
      >
        <Box
          // component={RouterLink}
          // to="/"
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            width: "72px",
            transition: "0.3s",
            position: "relative",
            "&:hover .apps": {
              visibility: "visible",
              opacity: 1,
            },
          }}
        >
          <Box
            className="logo"
            sx={{ transition: "0.3s", height: "100%", display: "flex", alignItems: "center" }}
          >
            <img src={appInfo.icon} alt="logo" style={{ width: "48px" }} />
          </Box>
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            borderColor: "rgba(255,255,255,0.1)",
            mr: 3,
          }}
        />
        <Box>{mdDown ? appInfo.shortCut : appInfo.fullName}</Box>
        <NavbarMenu onClose={() => setOpenMenu(false)} open={mdDown && openMenu} />
        <Button
          endIcon={
            <ChevronDownIcon
              fontSize="small"
              sx={{
                ml: 2,
                transition: "transform 250ms",
                transform: openMenu ? "rotate(180deg)" : "none",
              }}
            />
          }
          onClick={() => setOpenMenu(true)}
          sx={{
            color: "primary.contrastText",
            display: {
              md: "none",
              xs: "flex",
            },
            fontSize: "16px",
          }}
          variant="text"
        >
          Menu
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <SettingsPopover
          sx={{
            mx: 1,
            display: {
              md: "inline-flex",
              xs: "none",
            },
          }}
        />
        <LanguagePopover
          language={i18n.language}
          onLanguageChange={handleLanguageChange}
          sx={{
            mx: 1,
            display: {
              md: "inline-flex",
              xs: "none",
            },
          }}
        />
        <IconButton
          color="inherit"
          onClick={handleSwitchTheme}
          sx={{
            mx: 1,
            display: {
              md: "inline-flex",
              xs: "none",
            },
          }}
        >
          {darkMode ? <SunIcon /> : <MoonIcon />}
        </IconButton>
        <IconButton
          color="inherit"
          onClick={handleOpenAppDialog}
          sx={{
            mx: 1,
            display: {
              md: "inline-flex",
              xs: "none",
            },
          }}
        >
          <Apps />
        </IconButton>
        <AccountPopover
          darkMode={darkMode}
          onLanguageChange={handleLanguageChange}
          onSwitchTheme={handleSwitchTheme}
        />
        <AppDialog open={appDialog} onClose={handleCloseAppDialog} />
      </Toolbar>
    </AppBar>
  );
};

export default observer(Navbar);
