import { Suspense, lazy } from "react";
import type { PartialRouteObject } from "react-router";
import { Navigate } from "react-router-dom";
import { Layout } from "./layout/MainLayout";

import { LoadingScreen } from "./components/LoadingScreen";

import AuthGuard from "./components/Guards/AuthGuard";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

//USers pages
const Users = Loadable(
  lazy(() => import("./containers/Users").then((module) => ({ default: module.UsersContainer })))
);

const User = Loadable(
  lazy(() => import("./containers/[UserId]").then((module) => ({ default: module.User })))
);

const Domains = Loadable(
  lazy(() =>
    import("./containers/Domains").then((module) => ({ default: module.DomainsContainer }))
  )
);

const Apps = Loadable(
  lazy(() => import("./containers/Apps").then((module) => ({ default: module.AppsContainer })))
);

const Admins = Loadable(
  lazy(() => import("./containers/Admins").then((module) => ({ default: module.AdminsContainer })))
);

const Organizations = Loadable(
  lazy(() =>
    import("./containers/Organizations").then((module) => ({
      default: module.OrganizationsContainer,
    }))
  )
);

const Organization = Loadable(
  lazy(() =>
    import("./containers/[OrganizationId]").then((module) => ({
      default: module.Organization,
    }))
  )
);

const ApplicationsBackoffice = Loadable(
  lazy(() =>
    import("./containers/ApplicationsBackoffice").then((module) => ({
      default: module.ApplicationsBackofficeContainer,
    }))
  )
);

const RegisterNewOrganization = Loadable(
  lazy(() =>
    import("./containers/RegisterNewOrganization").then((module) => ({
      default: module.default,
    }))
  )
);

const Success = Loadable(
  lazy(() =>
    import("./containers/RegisterNewOrganization/success").then((module) => ({
      default: module.default,
    }))
  )
);

// Not found pages
const NotFound = Loadable(
  lazy(() => import("./containers/NotFound").then((module) => ({ default: module.NotFound })))
);

const routes: PartialRouteObject[] = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Navigate to="/users" replace />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "users/:userId",
        element: <User />,
      },
      {
        path: "domains",
        element: <Domains />,
      },
      {
        path: "apps",
        element: <Apps />,
      },
      {
        path: "admins",
        element: <Admins />,
      },
    ],
  },
  {
    path: "organizations",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Organizations />,
      },
      {
        path: ":organizationId",
        element: <Organization />,
      },
    ],
  },
  {
    path: "applications",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <ApplicationsBackoffice />,
      },
    ],
  },
  {
    path: "register",
    element: <RegisterNewOrganization />,
  },
  {
    path: "success",
    element: <Success />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
