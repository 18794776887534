import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Box } from "@material-ui/core";
import Loader from "../atoms/Loader/Loader";

const PrivateRoute = ({ children }) => <>{children}</>;

export default withAuthenticationRequired(PrivateRoute, {
  // Show a message while the user waits to be redirected to the login page.

  onRedirecting: () => (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
      <Loader />
    </Box>
  ),
});
