import type { FC } from "react";

import { Dialog, DialogContent, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";

import { Helpdesk } from "../../icons/signalApps/helpdesk";
import { AccessControl } from "../../icons/signalApps/accessControl";
import { Parking } from "../../icons/signalApps/parking";
import { ParkingSettings } from "../../icons/signalApps/withSettings/parkingSettings";

import { Elevators } from "../../icons/signalApps/elevators";
import { Visitors } from "../../icons/signalApps/visitors";
import { Hid } from "../../icons/signalApps/hid";

import { useSettings } from "src/contexts/SettingsContext";
import { VisitorsSettings } from "src/icons/signalApps/withSettings/visitorsSettings";
import { AccessControlSettings } from "src/icons/signalApps/withSettings/accessConstrolSettings";

import { SmartCity } from "src/icons/signalApps/smartCity";
import { Signal } from "src/icons/signalApps/signal";
import { useTranslation } from "react-i18next";

interface AppDialogProps {
  open: boolean;
  onClose: () => void;
}

export const AppDialog: FC<AppDialogProps> = (props) => {
  const { open, onClose, ...other } = props;
  const { t } = useTranslation();

  const { settings } = useSettings();

  const applicationsDomainTestEnvironment = Boolean(
    +process.env.REACT_APP_APPLICATIONS_DOMAIN_TEST_ENVIRONMENT
  );

  const applications = [
    {
      label: "Parking Administration",
      icon: <Parking theme={settings.theme} />,
      href: applicationsDomainTestEnvironment
        ? "https://test-admin.parking.signalos.io/"
        : "https://admin.parking.signalos.io/",
    },
    {
      label: "Parking Site Administration",
      icon: <ParkingSettings theme={settings.theme} />,
      href: null,
    },
    {
      label: "Helpdesk Operator",
      icon: <Helpdesk theme={settings.theme} />,
      href: null,
    },
    {
      label: "Visitors Administration",
      icon: <Visitors theme={settings.theme} />,
      href: applicationsDomainTestEnvironment
        ? "https://test-admin.visitors.signalos.io/"
        : "https://admin.visitors.signalos.io/",
    },
    {
      label: "Visitors Reception",
      icon: <Visitors theme={settings.theme} />,
      href: applicationsDomainTestEnvironment
        ? "https://test-reception.signalos.io/"
        : "https://reception.signalos.io/",
    },
    {
      label: "Visitors Site Administration",
      icon: <VisitorsSettings theme={settings.theme} />,
      href: null,
    },
    {
      label: "Access Control Administration",
      icon: <AccessControl theme={settings.theme} />,
      href: applicationsDomainTestEnvironment
        ? "https://test-admin.acs.signalos.io/"
        : "https://admin.acs.signalos.io/",
    },
    {
      label: "Access Control Site Administration",
      icon: <AccessControlSettings theme={settings.theme} />,
      href: applicationsDomainTestEnvironment
        ? "https://test-site-admin.acs.signalos.io/"
        : "https://site-admin.acs.signalos.io/",
    },
    { label: "Elevators", icon: <Elevators theme={settings.theme} />, href: null },
    {
      label: "HID Administration",
      icon: <Hid theme={settings.theme} />,
      href: applicationsDomainTestEnvironment
        ? "https://test-admin.mobile-access.signalos.io/"
        : "https://admin.mobile-access.signalos.io/",
    },
    { label: "Smart city", icon: <SmartCity theme={settings.theme} />, href: null },
    {
      label: "Platform",
      icon: <Signal theme={settings.theme} />,
      href: applicationsDomainTestEnvironment
        ? "https://test-platform.signalos.io/"
        : "https://platform.signalos.io/",
    },
  ];

  return (
    <Dialog onClose={onClose} open={open} {...other}>
      <DialogContent sx={{ p: 2 }}>
        <Grid container columns={12} sx={{ maxWidth: 540 }}>
          {applications.map((app, i) => (
            <Grid
              item
              key={app.label}
              sm={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                my: 1,
              }}
            >
              {app.href ? (
                <a
                  style={{ textDecoration: "none" }}
                  key={i}
                  href={app.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton
                    color="inherit"
                    onClick={() => {}}
                    sx={{
                      width: 75,
                      height: 75,
                    }}
                  >
                    {app.icon}
                  </IconButton>
                </a>
              ) : (
                <Tooltip title={t("common.availableSoon")}>
                  <IconButton
                    color="inherit"
                    onClick={() => {}}
                    sx={{
                      width: 75,
                      height: 75,
                    }}
                  >
                    {app.icon}
                  </IconButton>
                </Tooltip>
              )}
              <Typography sx={{ fontSize: "13px", fontWeight: 600, px: 2, textAlign: "center" }}>
                {app.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
