export const Helpdesk = ({ size = 64, theme }) =>
  theme === "light" ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M41.9634 16.6522C47.0046 11.6915 55.1779 11.6915 60.2191 16.6522C65.2603 21.6128 65.2603 29.6557 60.2191 34.6164L34.1395 60.2795C29.0983 65.2402 20.9249 65.2402 15.8837 60.2795C10.8426 55.3188 10.8426 47.276 15.8837 42.3153L41.9634 16.6522Z"
        fill="url(#paint0_linear_3259_5019)"
      />
      <path
        opacity="0.3"
        d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1574 8.68118 53.1574 16.724 48.1163 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
        fill="url(#paint1_linear_3259_5019)"
      />
      <path
        d="M8.94141 17.8824C8.94141 14.7636 11.4697 12.2354 14.5885 12.2354H49.412C52.5308 12.2354 55.0591 14.7636 55.0591 17.8824V46.1177C55.0591 49.2365 52.5308 51.7648 49.412 51.7648H14.5885C11.4697 51.7648 8.94141 49.2365 8.94141 46.1177V17.8824Z"
        fill="#FCAB2F"
      />
      <path
        d="M8.94141 16.0001C8.94141 13.9209 10.6269 12.2354 12.7061 12.2354H51.2944C53.3735 12.2354 55.0591 13.9209 55.0591 16.0001C55.0591 18.0792 53.3735 19.7648 51.2944 19.7648H12.7061C10.6269 19.7648 8.94141 18.0792 8.94141 16.0001Z"
        fill="#FFC163"
      />
      <path
        d="M29.1604 36.2352V32.335H25.5237V36.2352H23.2705V26.9062H25.5237V30.4375H29.1604V26.9062H31.4136V36.2352H29.1604Z"
        fill="white"
      />
      <path
        d="M41.173 32.9279C41.173 33.3847 41.1027 33.8239 40.9622 34.2455C40.8216 34.6672 40.6152 35.0405 40.3429 35.3655C40.0794 35.6818 39.7543 35.9365 39.3678 36.1298C38.9813 36.323 38.5421 36.4197 38.0502 36.4197C37.6461 36.4197 37.264 36.3406 36.9038 36.1825C36.5437 36.0156 36.2626 35.7916 36.0605 35.5105H36.0342V39.3975H33.8732V29.6733H35.9288V30.4771H35.9683C36.1703 30.2135 36.447 29.9852 36.7984 29.7919C37.1586 29.5899 37.5802 29.4888 38.0634 29.4888C38.5377 29.4888 38.9681 29.5811 39.3547 29.7655C39.7412 29.95 40.0662 30.2004 40.3297 30.5166C40.602 30.8328 40.8085 31.2018 40.949 31.6234C41.0983 32.0363 41.173 32.4711 41.173 32.9279ZM39.0779 32.9279C39.0779 32.7171 39.0428 32.5106 38.9725 32.3086C38.911 32.1066 38.8144 31.9309 38.6827 31.7815C38.5597 31.6234 38.4016 31.4961 38.2083 31.3994C38.015 31.3028 37.791 31.2545 37.5363 31.2545C37.2903 31.2545 37.0707 31.3028 36.8775 31.3994C36.6842 31.4961 36.5173 31.6234 36.3768 31.7815C36.245 31.9397 36.1396 32.1197 36.0605 32.3218C35.9903 32.5238 35.9551 32.7303 35.9551 32.9411C35.9551 33.1519 35.9903 33.3583 36.0605 33.5604C36.1396 33.7624 36.245 33.9425 36.3768 34.1006C36.5173 34.2587 36.6842 34.3861 36.8775 34.4827C37.0707 34.5793 37.2903 34.6277 37.5363 34.6277C37.791 34.6277 38.015 34.5793 38.2083 34.4827C38.4016 34.3861 38.5597 34.2587 38.6827 34.1006C38.8144 33.9425 38.911 33.7624 38.9725 33.5604C39.0428 33.3495 39.0779 33.1387 39.0779 32.9279Z"
        fill="white"
      />
      <rect x="39.0586" y="43.7646" width="8" height="8" fill="#E08802" />
      <path
        d="M54.1174 43.7646H39.0586L53.4519 58.158C54.0448 58.7509 55.0586 58.3309 55.0586 57.4924V44.7058C55.0586 44.186 54.6372 43.7646 54.1174 43.7646Z"
        fill="#FF9A00"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3259_5019"
          x1="15.8838"
          y1="60.2794"
          x2="59.5055"
          y2="15.9497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCAB2F" />
          <stop offset="1" stopColor="#FCE2BC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3259_5019"
          x1="48.1162"
          y1="3.72065"
          x2="4.4945"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCAB2F" />
          <stop offset="1" stopColor="#FCE2BC" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_180)">
        <path
          opacity="0.6"
          d="M41.9634 16.6522C47.0046 11.6915 55.1779 11.6915 60.2191 16.6522C65.2603 21.6128 65.2603 29.6557 60.2191 34.6164L34.1395 60.2795C29.0983 65.2402 20.9249 65.2402 15.8837 60.2795C10.8426 55.3188 10.8426 47.276 15.8837 42.3153L41.9634 16.6522Z"
          fill="url(#paint0_linear_3_180)"
        />
        <path
          opacity="0.6"
          d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1574 8.68118 53.1574 16.724 48.1163 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
          fill="url(#paint1_linear_3_180)"
        />
        <path
          d="M8.94238 17.8823C8.94238 14.7635 11.4707 12.2352 14.5894 12.2352H49.413C52.5318 12.2352 55.06 14.7635 55.06 17.8823V46.1176C55.06 49.2364 52.5318 51.7647 49.413 51.7647H14.5894C11.4707 51.7647 8.94238 49.2364 8.94238 46.1176V17.8823Z"
          fill="#FCAB2F"
        />
        <path
          d="M8.94238 16C8.94238 13.9208 10.6279 12.2352 12.7071 12.2352H51.2953C53.3745 12.2352 55.06 13.9208 55.06 16C55.06 18.0791 53.3745 19.7647 51.2953 19.7647H12.7071C10.6279 19.7647 8.94238 18.0791 8.94238 16Z"
          fill="#FFC163"
        />
        <path
          d="M29.1643 36.2351V32.3348H25.5276V36.2351H23.2744V26.9061H25.5276V30.4374H29.1643V26.9061H31.4175V36.2351H29.1643Z"
          fill="white"
        />
        <path
          d="M41.1769 32.9278C41.1769 33.3846 41.1066 33.8238 40.9661 34.2454C40.8255 34.6671 40.6191 35.0404 40.3468 35.3654C40.0833 35.6817 39.7582 35.9364 39.3717 36.1297C38.9852 36.3229 38.546 36.4196 38.0541 36.4196C37.65 36.4196 37.2679 36.3405 36.9077 36.1824C36.5476 36.0155 36.2665 35.7915 36.0644 35.5104H36.0381V39.3974H33.8771V29.6732H35.9327V30.477H35.9722C36.1742 30.2134 36.451 29.985 36.8023 29.7918C37.1625 29.5898 37.5841 29.4887 38.0673 29.4887C38.5416 29.4887 38.9721 29.581 39.3586 29.7654C39.7451 29.9499 40.0701 30.2003 40.3336 30.5165C40.6059 30.8327 40.8124 31.2017 40.9529 31.6233C41.1022 32.0362 41.1769 32.471 41.1769 32.9278ZM39.0819 32.9278C39.0819 32.717 39.0467 32.5105 38.9764 32.3085C38.915 32.1065 38.8183 31.9308 38.6866 31.7814C38.5636 31.6233 38.4055 31.4959 38.2122 31.3993C38.019 31.3027 37.795 31.2544 37.5402 31.2544C37.2942 31.2544 37.0746 31.3027 36.8814 31.3993C36.6881 31.4959 36.5212 31.6233 36.3807 31.7814C36.2489 31.9396 36.1435 32.1196 36.0644 32.3217C35.9942 32.5237 35.959 32.7301 35.959 32.941C35.959 33.1518 35.9942 33.3582 36.0644 33.5603C36.1435 33.7623 36.2489 33.9424 36.3807 34.1005C36.5212 34.2586 36.6881 34.386 36.8814 34.4826C37.0746 34.5792 37.2942 34.6276 37.5402 34.6276C37.795 34.6276 38.019 34.5792 38.2122 34.4826C38.4055 34.386 38.5636 34.2586 38.6866 34.1005C38.8183 33.9424 38.915 33.7623 38.9764 33.5603C39.0467 33.3494 39.0819 33.1386 39.0819 32.9278Z"
          fill="white"
        />
        <rect x="39.0615" y="43.7646" width="8" height="8" fill="#E08802" />
        <path
          d="M54.1203 43.7646H39.0615L53.4548 58.158C54.0477 58.7509 55.0615 58.3309 55.0615 57.4924V44.7058C55.0615 44.186 54.6401 43.7646 54.1203 43.7646Z"
          fill="#FF9A00"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3_180"
          x1="15.8838"
          y1="60.2794"
          x2="59.5055"
          y2="15.9497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCAB2F" />
          <stop offset="0.973958" stopColor="#FCAB2F" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_180"
          x1="48.1162"
          y1="3.72065"
          x2="4.4945"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCAB2F" />
          <stop offset="0.973958" stopColor="#FCAB2F" stopOpacity="0.2" />
        </linearGradient>
        <clipPath id="clip0_3_180">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
