import api from "./clients/api";

export const registerOrganization = async (
  organizationName: string,
  ownerEmail: string,
  refer: string,
  tokenReCaptcha: string,
  appBundleId: string
): Promise<void> => {
  const { data } = await api.post(
    "/organization-register/start",
    {
      organizationName,
      ownerEmail,
      refer,
      appBundleId,
    },
    {
      headers: {
        recaptcha: tokenReCaptcha,
      },
    }
  );

  return data;
};

export const confirmNewAdmin = async () => {
  const { data } = await api.post<void>("/organization/administrator/confirm");

  return data;
};
