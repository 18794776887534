import { useState, useEffect } from "react";
import type { FC, ElementType } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Drawer, List } from "@material-ui/core";
import { NavbarMenuItem } from "../../components/molecules/NavbarMenuItem";

import { CustomUsers as UsersIcon } from "../../icons/custom-users";
import { Calendar as CalendarIcon } from "../../icons/calendar";
import { User as UserIcon } from "../../icons/user";
import { OfficeBuilding as OfficeBuildingIcon } from "../../icons/office-building";

interface NavbarMenuProps {
  onClose: () => void;
  open: boolean;
}

interface Item {
  href?: string;
  external?: boolean;
  icon: ElementType;
  items?: Array<{ href: string; title: string }>;
  title: string;
}

const items: Item[] = [
  { icon: UsersIcon, title: "Sessions", href: "/sessions" },
  { icon: CalendarIcon, title: "Parking groups", href: "/parking-groups" },
  { icon: UserIcon, title: "Employees", href: "/employees" },
  { icon: OfficeBuildingIcon, title: "Parking spots", href: "/parking-spots" },
];

export const NavbarMenu: FC<NavbarMenuProps> = (props) => {
  const { open, onClose } = props;
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState<Item | null>(null);
  const [activeItem, setActiveItem] = useState<Item | null>(null);
  const [activeHref, setActiveHref] = useState("");

  const handleOpenItem = (item: Item): void => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    items.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });
  }, [pathname]);

  return (
    <Drawer
      anchor="top"
      onClose={onClose}
      open={open}
      transitionDuration={0}
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: "#2B2F3C",
          color: "#B2B7C8",
          display: "flex",
          flexDirection: "column",
          top: 64,
          maxHeight: "calc(100% - 64px)",
          width: "100vw",
        },
      }}
    >
      <List>
        {activeItem &&
          items.map((item) => (
            <NavbarMenuItem
              active={activeItem?.title === item.title}
              activeHref={activeHref}
              key={item.title}
              onClose={onClose}
              onOpenItem={() => handleOpenItem(item)}
              open={openedItem?.title === item.title}
              {...item}
            />
          ))}
      </List>
    </Drawer>
  );
};
