import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      common: {
        page: "Page",
        of: "of",
        cancel: "Cancel",
        confirm: "Confirm",
        delete: "Delete",
        required: "Field is required",
        yes: "Yes",
        no: "No",
        logout: "Log out",
        search: "Search",
        perPage: "Per page:",
        success: "Success",
        error: "Error",
        availableSoon: "Available soon",
      },
      users: {
        title: "Users",
        tab: "Users | SignalOS",
        addUser: "Add user",
        table: {
          columns: {
            user: "User",
            identifier: "Identifier",
            created: "Created",
            lastLogin: "Last login",
          },
          bulkActions: "Bulk actions",
          attachApps: "Attach apps to",
          detachApps: "Detach apps from",
          users: "users",
        },
        batchDialog: {
          titleAttach: "Attach applications",
          titleDetach: "Detach applications",
          toastErrorNotSelected: "Applications was not selected",
          toastSuccessAttached: "Apps attached successfully",
          toastSuccessDetached: "Apps detached successfully",
          noApps: "No apps available.",
          name: "Name",
          identifier: "Identifier",
        },
        addUserDialog: {
          title: "Add new user",
          error: "Wrong email format",
        },
        removeDialog: {
          title: "Remove user from organization",
          message: "Are you sure, you want to remove user",
        },
        toastSuccessAddUser: "User added successfully",
        toastSuccessRemoveUser: "User removed successfully",
      },
      user: {
        since: "Since",
        users: "Users",
        info: {
          title: "General information",
          email: "Email",
          identifier: "Identifier",
          username: "Username",
        },
        applications: {
          title: "Applications",
          none: "None",
          addApplication: "Add application",
        },
        devices: {
          title: "Devices",
          table: {
            system: "System",
            systemVersion: "System Version",
            created: "Created",
            updated: "Updated",
          },
        },
        attachDialog: {
          title: "Attach application",
          notSelected: "Application was not selected",
          toastSuccess: "App attached successfully",
          noApps: "No apps available.",
          name: "Name",
          identifier: "Identifier",
        },
        detachDialog: {
          title: "Detach application",
          message: "Do you want to detach this application?",
          toastSuccess: "App detached successfully",
        },
      },
      apps: {
        title: "Apps",
        tab: "Apps | SignalOS",
        tooltipButton: "You need to pick apps first",
        addDefaultAppButton: "Set checked apps as default",
        defaultAppDialog: {
          title: "Set chosen applications as default",
          messagePart1: "You are setting applications:",
          messagePart2: "as default for Your organization.",
          optional: "Your organization will not have default applications.",
        },
        table: {
          columns: {
            name: "Name",
            identifier: "Identifier",
            isDefault: "Is default",
          },
        },
        toastSuccess: "Applications set as default successfully",
      },
      domains: {
        title: "Domains",
        tab: "Domains | SignalOS",
        addDomain: "Add domain",
        table: {
          domain: "Domain",
          created: "Created",
          delete: "Delete",
        },
        removeDialog: {
          title: "Are you sure?",
          messagePart1: "Are You sure, You want to delete",
          messagePart2: "This cant be undone.",
        },
        toastSuccessAdd: "Domain added successfully",
        toastSuccessRemove: "Domain removed successfully",
        addDialog: {
          title: "Add new domain",
          domain: "Domain",
        },
      },
      organizations: {
        title: "Organizations",
        tab: "Organizations | SignalOS",
        add: "Add organization",
        name: "Name",
        sites: "Buildings",
        apps: "Applications",
        manageApps: "Manage apps",
        manageSites: "Manage sites",
        thereIsNoSitesToAttach: "There is no sites to attach",
        sorryYouDontHavePermission: "Sorry You don't have permission to see that page",
        manageOrganizations: "Manage organizations",
      },
      organization: {
        info: "Information",
        identifier: "Identifier",
        autoOnBoard: "Auto onboard",
        none: "None",
        domains: {
          title: "Domains",
          thereIsNo: "There are no domains added",
        },
        apps: {
          title: "Applications",
          manage: "Manage applications",
          name: "Name",
          identifier: "Identifier",
          thereAreNoApps: "Sorry there are no applications to manage",
        },
        sites: {
          title: "Sites",
          manage: "Manage sites",
        },
        license: {
          dialogTitle: "Manage license - application:",
          success: "License updates successfully",
          thereIsNoRulesToManage: "There are no rules to manage",
          rule: "Rule",
          value: "Value",
          description: "Description",
          manageLicense: "Manage license",
        },
      },
      admins: {
        title: "Admins",
        tab: "Admins | SignalOS",
        add: "Create admin",
        table: {
          columns: {
            email: "Email",
            isVerified: "Is verified",
          },
          data: {
            areYouSureYouWantRemove: "Are you sure you want to remove:",
          },
        },
      },
      applications: {
        title: "Applications",
        tab: "Applications | SignalOS",
        name: "Name",
        appBundle: "App bundle",
        licenseTemplates: "License templates",
        showLicense: "Show license",
        thereAreNoLicense: "There are no license for that app",
        appLicenses: "Application licenses",
      },
    },
  },
  pl: {
    translation: {
      common: {
        page: "Strona",
        of: "z",
        cancel: "Anuluj",
        confirm: "Potwierdź",
        delete: "Usuń",
        required: "Pole jest wymagane",
        yes: "Tak",
        no: "Nie",
        logout: "Wyloguj",
        search: "Szukaj",
        perPage: "Na stronę:",
        success: "Sukces",
        error: "Błąd",
        availableSoon: "Dostępne wkrótce",
      },
      users: {
        title: "Użytkownicy",
        tab: "Użytkownicy | SignalOS",
        addUser: "Dodaj użytkownika",
        table: {
          columns: {
            user: "Użytkownik",
            identifier: "Identyfikator",
            created: "Stworzony",
            lastLogin: "Ostatnie logowanie",
          },
          bulkActions: "Akcja masowa",
          attachApps: "Przypisz aplikacje do",
          detachApps: "Odczep aplikacje od",
          users: "użytkowników.",
        },
        batchDialog: {
          titleAttach: "Przypisz aplikacje",
          titleDetach: "Odpisz aplikacje",
          toastErrorNotSelected: "Nie zostały wybrane żadne aplikacje",
          toastSuccessAttached: "Aplikacje przypisane poprawnie",
          toastSuccessDetached: "Aplikacje odpisane poprawnie",
          noApps: "Brak aplikacji do przypisania.",
          name: "Nazwa",
          identifier: "Identyfikator",
        },
        addUserDialog: {
          title: "Dodaj nowego użytkownika",
          error: "Błędny format",
        },
        removeDialog: {
          title: "Usuń użytkownika z organizacji",
          message: "Czy na pewno chcesz usunąć użytkownika",
        },
        toastSuccessAddUser: "Użytkownik dodany poprawnie",
        toastSuccessRemoveUser: "Użytkownik usunięty poprawnie",
      },
      user: {
        since: "Od",
        users: "Użytkownik",
        info: {
          title: "Ogólne informacje",
          email: "Email",
          identifier: "Identyfikator",
          username: "Nazwa",
        },
        applications: {
          title: "Aplikacje",
          none: "Brak",
          addApplication: "Dodaj aplikacje",
        },
        devices: {
          title: "Urządzenia",
          table: {
            system: "System",
            systemVersion: "Wersja systemu",
            created: "Utworzone",
            updated: "Uaktualnione",
          },
        },
        attachDialog: {
          title: "Przypisz aplikacje",
          notSelected: "Żadne aplikacje nie zostały wybrane",
          toastSuccess: "Aplikacje przypisane poprawnie",
          noApps: "Brak dostępnych aplikacji.",
          name: "Nazwa",
          identifier: "Identyfikator",
        },
        detachDialog: {
          title: "Odczep aplikację",
          message: "Czy na pewno chcesz odczepić tą aplikację?",
          toastSuccess: "Aplikacja odczepiona poprawnie",
        },
      },
      apps: {
        title: "Aplikacje",
        tab: "Aplikacje | SignalOS",
        tooltipButton: "Najpierw wybierz aplikacje",
        addDefaultAppButton: "Ustaw wybrane aplikacje jako domyślne",
        defaultAppDialog: {
          title: "Ustaw wybrane aplikacje jako domyślne dla Twojej organizacji",
          messagePart1: "Ustawiasz aplikacje:",
          messagePart2: "jako domyślne dla Twojej organizacji.",
          optional: "Twoja organizacja nie będzie miała domyślnych aplikacji.",
        },
        table: {
          columns: {
            name: "Nazwa",
            identifier: "Identyfikator",
            isDefault: "Jest domyślna",
          },
        },
        toastSuccess: "Aplikacje ustawione jako domyślne poprawnie",
      },
      domains: {
        title: "Domeny",
        tab: "Domeny | SignalOS",
        addDomain: "Dodaj domenę",
        table: {
          domain: "Domena",
          created: "Utworzona",
          delete: "Usuń",
        },
        removeDialog: {
          title: "Czy na pewno?",
          messagePart1: "Czy na pewno chcesz usunąć domenę:",
          messagePart2: "Tego nie można cofnąć.",
        },
        toastSuccessAdd: "Domena dodana poprawnie",
        toastSuccessRemove: "Domena usunięta poprawnie",
        addDialog: {
          title: "Dodaj nową domenę",
          domain: "Domena",
        },
      },
      organizations: {
        title: "Organizacje",
        tab: "Organizacje | SignalOS",
        add: "Dodaje organizację",
        name: "Nazwa",
        sites: "Budynek",
        apps: "Aplikacje",
        manageApps: "Zarządzaj aplikacjami",
        manageSites: "Zarządzaj budynkami",
        thereIsNoSitesToAttach: "Brak budynków do przypisania",
        sorryYouDontHavePermission: "Przykro nam, nie masz uprawnień do odwiedzenia tej strony",
        manageOrganizations: "Zarządzaj organizacją",
      },
      organization: {
        info: "Informacje",
        identifier: "Identyfikator",
        autoOnBoard: "Auto onboarding",
        none: "Brak",
        domains: {
          title: "Domeny",
          thereIsNo: "Brak dodanych domen",
        },
        apps: {
          title: "Aplikacje",
          manage: "Zarządzaj aplikacjami",
          name: "Nazwa",
          identifier: "Identyfikator",
          thereAreNoApps: "Przykro nam, brak aplikacji do zarządzania",
        },
        sites: {
          title: "Budynki",
          manage: "Zarządzaj budynkami",
        },
        license: {
          dialogTitle: "Zarządzaj licencjami - aplikacja:",
          success: "Licencje zaktualizowane poprawnie",
          thereIsNoRulesToManage: "Brak zasad do uaktualnienia",
          rule: "Zasada",
          value: "Wartość",
          description: "Opis",
          manageLicense: "Zarządzaj licencjami",
        },
      },
      admins: {
        title: "Administratorzy",
        tab: "Administratorzy | SignalOS",
        add: "Dodaj administratora",
        table: {
          columns: {
            email: "Email",
            isVerified: "Jest zweryfikowany",
          },
          data: {
            areYouSureYouWantRemove: "Czy na pewno chcesz usunąć:",
          },
        },
      },
      applications: {
        title: "Aplikacje",
        tab: "Aplikacje | SignalOS",
        name: "Nazwa",
        appBundle: "Kod aplikacji",
        licenseTemplates: "Szablony licencji",
        showLicense: "Pokaż licencje",
        thereAreNoLicense: "Brak licencji dla tej aplikacji",
        appLicenses: "Licencje aplikacji",
      },
    },
  },
};

export const initializeI18n = (lng: string): void => {
  i18n.use(initReactI18next).init({
    resources,
    lng,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};
