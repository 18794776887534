import axios from "axios";

import store from "../../store/ApplicationStore";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${store.auth.token}`,
  },
});

api.interceptors.request.use((config) => {
  const {
    auth: { token },
    user,
  } = store;

  // config.headers["Accept-Language"] = i18n.language || "en";
  if (user.userOrganizationId) {
    config.headers["s-organization-token"] = user.userOrganizationId;
  }

  if (!token) {
    return config;
  }

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default api;
