export const Elevators = ({ size = 64, theme }) =>
  theme === "light" ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M41.9634 16.6524C47.0046 11.6917 55.1779 11.6917 60.2191 16.6524C65.2603 21.6131 65.2603 29.6559 60.2191 34.6166L34.1395 60.2797C29.0983 65.2404 20.9249 65.2404 15.8838 60.2797C10.8426 55.3191 10.8426 47.2762 15.8837 42.3155L41.9634 16.6524Z"
        fill="url(#paint0_linear_3259_5032)"
      />
      <path
        opacity="0.35"
        d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1162 3.72051C53.1574 8.68118 53.1574 16.724 48.1162 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
        fill="url(#paint1_linear_3259_5032)"
      />
      <path
        d="M8.94141 17.8822C8.94141 14.7634 11.4697 12.2351 14.5885 12.2351H49.412C52.5307 12.2351 55.059 14.7634 55.059 17.8822V46.1174C55.059 49.2362 52.5307 51.7645 49.412 51.7645H14.5885C11.4697 51.7645 8.94141 49.2362 8.94141 46.1174V17.8822Z"
        fill="#EA6060"
      />
      <rect x="30.5889" y="12.2351" width="2.82353" height="39.5294" fill="#BF4B4B" />
      <path
        d="M24.2012 36.7058V26.0442H31.3692V28.2277H26.6859V30.2305H31.1132V32.2936H26.6859V34.5072H31.6402V36.7058H24.2012Z"
        fill="white"
      />
      <path
        d="M38.4273 36.7058H35.7619L32.8103 29.2065H35.536L37.1172 34.0705H37.1623L38.7435 29.2065H41.3788L38.4273 36.7058Z"
        fill="white"
      />
      <path
        d="M6.58887 25.8823C6.58887 25.3625 7.01025 24.9412 7.53004 24.9412H10.3536C10.8734 24.9412 11.2947 25.3625 11.2947 25.8823V39.0588C11.2947 39.5786 10.8734 40 10.3536 40H7.53004C7.01025 40 6.58887 39.5786 6.58887 39.0588V25.8823Z"
        fill="#BF4B4B"
      />
      <circle cx="8.94215" cy="37.1763" r="0.941176" fill="white" />
      <circle cx="8.94215" cy="33.8823" r="0.941176" fill="white" />
      <path
        d="M24.9414 5.64699C24.9414 5.12719 25.3628 4.70581 25.8826 4.70581H38.1179C38.6377 4.70581 39.059 5.12719 39.059 5.64699V8.47051C39.059 8.99031 38.6377 9.41169 38.1179 9.41169H25.8826C25.3628 9.41169 24.9414 8.99031 24.9414 8.47051V5.64699Z"
        fill="#BF4B4B"
      />
      <circle cx="36.7068" cy="7.05885" r="0.941176" fill="#C3ED87" />
      <defs>
        <linearGradient
          id="paint0_linear_3259_5032"
          x1="15.8838"
          y1="60.2796"
          x2="59.5055"
          y2="15.95"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BF4B4B" />
          <stop offset="1" stopColor="#FFC4C4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3259_5032"
          x1="48.1162"
          y1="3.72065"
          x2="4.49449"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BF4B4B" />
          <stop offset="1" stopColor="#FFC4C4" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        d="M41.9633 16.6523C47.0045 11.6916 55.1779 11.6916 60.219 16.6523C65.2602 21.613 65.2602 29.6558 60.219 34.6165L34.1394 60.2796C29.0982 65.2403 20.9249 65.2403 15.8837 60.2796C10.8425 55.319 10.8425 47.2761 15.8837 42.3154L41.9633 16.6523Z"
        fill="url(#paint0_linear_3_206)"
      />
      <path
        opacity="0.7"
        d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1162 3.72051C53.1574 8.68118 53.1574 16.724 48.1162 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
        fill="url(#paint1_linear_3_206)"
      />
      <path
        d="M8.94141 17.8823C8.94141 14.7635 11.4697 12.2352 14.5885 12.2352H49.412C52.5307 12.2352 55.059 14.7635 55.059 17.8823V46.1176C55.059 49.2363 52.5307 51.7646 49.412 51.7646H14.5885C11.4697 51.7646 8.94141 49.2363 8.94141 46.1176V17.8823Z"
        fill="#EA6060"
      />
      <rect x="30.5889" y="12.2352" width="2.82353" height="39.5294" fill="#BF4B4B" />
      <path
        d="M24.2012 36.7058V26.0442H31.3692V28.2277H26.6859V30.2305H31.1132V32.2936H26.6859V34.5072H31.6402V36.7058H24.2012Z"
        fill="white"
      />
      <path
        d="M38.4273 36.7058H35.7619L32.8103 29.2065H35.536L37.1172 34.0705H37.1623L38.7435 29.2065H41.3788L38.4273 36.7058Z"
        fill="white"
      />
      <path
        d="M6.58887 25.8823C6.58887 25.3625 7.01025 24.9411 7.53004 24.9411H10.3536C10.8734 24.9411 11.2947 25.3625 11.2947 25.8823V39.0587C11.2947 39.5785 10.8734 39.9999 10.3536 39.9999H7.53004C7.01025 39.9999 6.58887 39.5785 6.58887 39.0587V25.8823Z"
        fill="#BF4B4B"
      />
      <circle cx="8.94215" cy="37.1764" r="0.941176" fill="white" />
      <circle cx="8.94215" cy="33.8823" r="0.941176" fill="white" />
      <path
        d="M24.9414 5.647C24.9414 5.1272 25.3628 4.70583 25.8826 4.70583H38.1179C38.6377 4.70583 39.059 5.1272 39.059 5.647V8.47053C39.059 8.99033 38.6377 9.4117 38.1179 9.4117H25.8826C25.3628 9.4117 24.9414 8.99033 24.9414 8.47053V5.647Z"
        fill="#BF4B4B"
      />
      <circle cx="36.7068" cy="7.05877" r="0.941176" fill="#C3ED87" />
      <defs>
        <linearGradient
          id="paint0_linear_3_206"
          x1="15.8838"
          y1="60.2795"
          x2="59.5054"
          y2="15.9499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BF4B4B" stopOpacity="0.9" />
          <stop offset="1" stopColor="#BF4B4B" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_206"
          x1="48.1162"
          y1="3.72065"
          x2="4.49449"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BF4B4B" stopOpacity="0.9" />
          <stop offset="1" stopColor="#BF4B4B" stopOpacity="0.2" />
        </linearGradient>
      </defs>
    </svg>
  );
