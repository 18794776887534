export const ParkingSettings = ({ size = 64, theme }) =>
  theme === "light" ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M41.9634 16.6522C47.0046 11.6915 55.1779 11.6915 60.2191 16.6522C65.2603 21.6128 65.2603 29.6557 60.2191 34.6164L34.1395 60.2795C29.0983 65.2402 20.9249 65.2402 15.8837 60.2795C10.8426 55.3188 10.8426 47.276 15.8837 42.3153L41.9634 16.6522Z"
        fill="url(#paint0_linear_16_13)"
      />
      <path
        opacity="0.35"
        d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1574 8.68118 53.1574 16.724 48.1163 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
        fill="url(#paint1_linear_16_13)"
      />
      <path
        d="M10.7793 14.6041C11.0092 12.7148 12.6132 11.2942 14.5165 11.2942H48.9663C50.8506 11.2942 52.4446 12.6872 52.6971 14.5545L57.3056 48.6398C57.6111 50.8989 55.8545 52.909 53.5749 52.909H10.3687C8.10921 52.909 6.35867 50.9325 6.63161 48.6895L10.7793 14.6041Z"
        fill="#FF9371"
      />
      <path
        d="M23.2945 11.2942L21.4121 13.1765L22.285 14.665L25.6474 11.2942H23.2945Z"
        fill="#D3542B"
      />
      <path
        d="M34.1177 11.2942L32.2354 13.1765L33.1082 14.665L36.4706 11.2942H34.1177Z"
        fill="#D3542B"
      />
      <path
        d="M44.4712 11.2942L42.5889 13.1765L43.4618 14.665L46.8242 11.2942H44.4712Z"
        fill="#D3542B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1763 16.6118H16.674V15.3883H15.049C14.4946 15.3883 14.0299 15.8072 13.9725 16.3586L10.6865 47.9368L11.9035 48.0634L15.1763 16.6118Z"
        fill="#FFB7A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.355 16.6118H46.8572V15.3883H48.4822C49.0366 15.3883 49.5014 15.8072 49.5588 16.3586L52.8447 47.9368L51.6278 48.0634L48.355 16.6118Z"
        fill="#FFB7A0"
      />
      <path
        d="M21.4118 7.52948C22.0616 7.52948 22.5883 8.0562 22.5883 8.70595L22.5883 13.8824C22.5883 14.5322 22.0616 15.0589 21.4118 15.0589C20.7621 15.0589 20.2354 14.5322 20.2354 13.8824L20.2354 8.70595C20.2354 8.0562 20.7621 7.52948 21.4118 7.52948Z"
        fill="#FE754A"
      />
      <rect
        x="20.2354"
        y="13.6472"
        width="0.941177"
        height="2.35294"
        transform="rotate(-90 20.2354 13.6472)"
        fill="#FFB49C"
      />
      <rect
        x="20.2354"
        y="11.7649"
        width="0.941177"
        height="2.35294"
        transform="rotate(-90 20.2354 11.7649)"
        fill="#FFB49C"
      />
      <rect
        x="20.2354"
        y="9.88251"
        width="0.941177"
        height="2.35294"
        transform="rotate(-90 20.2354 9.88251)"
        fill="#FFB49C"
      />
      <path
        d="M32.236 7.52948C32.8858 7.52948 33.4125 8.0562 33.4125 8.70595L33.4125 13.8824C33.4125 14.5322 32.8858 15.0589 32.236 15.0589C31.5863 15.0589 31.0596 14.5322 31.0596 13.8824L31.0596 8.70595C31.0596 8.0562 31.5863 7.52948 32.236 7.52948Z"
        fill="#FE754A"
      />
      <rect
        x="31.0596"
        y="13.6472"
        width="0.941177"
        height="2.35294"
        transform="rotate(-90 31.0596 13.6472)"
        fill="#FFB49C"
      />
      <rect
        x="31.0596"
        y="11.7649"
        width="0.941177"
        height="2.35294"
        transform="rotate(-90 31.0596 11.7649)"
        fill="#FFB49C"
      />
      <rect
        x="31.0596"
        y="9.88251"
        width="0.941177"
        height="2.35294"
        transform="rotate(-90 31.0596 9.88251)"
        fill="#FFB49C"
      />
      <path
        d="M42.5886 7.52948C43.2383 7.52948 43.7651 8.0562 43.7651 8.70595L43.7651 13.8824C43.7651 14.5322 43.2383 15.0589 42.5886 15.0589C41.9388 15.0589 41.4121 14.5322 41.4121 13.8824L41.4121 8.70595C41.4121 8.0562 41.9388 7.52948 42.5886 7.52948Z"
        fill="#FE754A"
      />
      <rect
        x="41.4121"
        y="13.6472"
        width="0.941177"
        height="2.35294"
        transform="rotate(-90 41.4121 13.6472)"
        fill="#FFB49C"
      />
      <rect
        x="41.4121"
        y="11.7649"
        width="0.941177"
        height="2.35294"
        transform="rotate(-90 41.4121 11.7649)"
        fill="#FFB49C"
      />
      <rect
        x="41.4121"
        y="9.88251"
        width="0.941177"
        height="2.35294"
        transform="rotate(-90 41.4121 9.88251)"
        fill="#FFB49C"
      />
      <path
        d="M33.3317 29.3271C33.3317 29.9496 33.2163 30.4766 32.9854 30.9083C32.7545 31.33 32.4432 31.6713 32.0517 31.9323C31.6602 32.1933 31.2084 32.3841 30.6964 32.5045C30.1844 32.625 29.6523 32.6853 29.1002 32.6853H27.8202V36.706H25.2451V26.0443H29.1604C29.7427 26.0443 30.2848 26.1045 30.7868 26.225C31.2988 26.3354 31.7405 26.5212 32.1119 26.7822C32.4934 27.0332 32.7896 27.3695 33.0004 27.7911C33.2213 28.2027 33.3317 28.7147 33.3317 29.3271ZM30.7566 29.3422C30.7566 29.0912 30.7065 28.8854 30.6061 28.7248C30.5057 28.5642 30.3701 28.4387 30.1995 28.3483C30.0288 28.258 29.833 28.1977 29.6122 28.1676C29.4014 28.1375 29.1805 28.1224 28.9496 28.1224H27.8202V30.6373H28.9044C29.1454 30.6373 29.3763 30.6172 29.5971 30.577C29.818 30.5369 30.0137 30.4666 30.1844 30.3662C30.3651 30.2658 30.5057 30.1353 30.6061 29.9747C30.7065 29.804 30.7566 29.5932 30.7566 29.3422Z"
        fill="white"
      />
      <path
        d="M39.7118 31.2245C39.6014 31.1944 39.491 31.1744 39.3805 31.1643C39.2801 31.1543 39.1797 31.1493 39.0794 31.1493C38.7481 31.1493 38.467 31.2095 38.2361 31.33C38.0152 31.4504 37.8345 31.596 37.6939 31.7667C37.5634 31.9373 37.4681 32.1231 37.4078 32.3238C37.3476 32.5246 37.3175 32.7003 37.3175 32.8509V36.706H34.8478V29.2067H37.2271V30.2909H37.2572C37.448 29.9094 37.719 29.5982 38.0704 29.3573C38.4218 29.1163 38.8284 28.9958 39.2902 28.9958C39.3906 28.9958 39.4859 29.0009 39.5763 29.0109C39.6767 29.0209 39.757 29.036 39.8172 29.0561L39.7118 31.2245Z"
        fill="white"
      />
      <circle cx="52" cy="12" r="12" fill="black" />
      <path
        d="M53.4225 7.43359C54.2145 6.93306 55.2428 7.5268 55.2053 8.46289C55.1642 9.48994 55.7179 10.449 56.6279 10.9268C57.4573 11.3624 57.4573 12.5499 56.6279 12.9854C55.7179 13.4633 55.1642 14.4223 55.2053 15.4494C55.2428 16.3855 54.2145 16.9792 53.4225 16.4787C52.5537 15.9295 51.4463 15.9295 50.5774 16.4787C49.7855 16.9792 48.7571 16.3855 48.7946 15.4494C48.8358 14.4223 48.2821 13.4633 47.372 12.9854C46.5426 12.5499 46.5426 11.3624 47.372 10.9268C48.2821 10.449 48.8358 9.48994 48.7946 8.46289C48.7571 7.5268 49.7855 6.93306 50.5774 7.43359C51.4463 7.98276 52.5537 7.98276 53.4225 7.43359Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <circle cx="52" cy="12" r="1" fill="white" />

      <defs>
        <linearGradient
          id="paint0_linear_16_13"
          x1="15.8838"
          y1="60.2794"
          x2="59.5055"
          y2="15.9497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F26E44" />
          <stop offset="1" stopColor="#FFC2AF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16_13"
          x1="48.1162"
          y1="3.72065"
          x2="4.49449"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F26E44" />
          <stop offset="1" stopColor="#FFC2AF" />
        </linearGradient>
        <clipPath id="clip0_16_13">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16_154)">
        <path
          opacity="0.5"
          d="M41.9634 16.6522C47.0046 11.6915 55.1779 11.6915 60.2191 16.6522C65.2603 21.6128 65.2603 29.6557 60.2191 34.6164L34.1395 60.2795C29.0983 65.2402 20.9249 65.2402 15.8837 60.2795C10.8426 55.3188 10.8426 47.276 15.8837 42.3153L41.9634 16.6522Z"
          fill="url(#paint0_linear_16_154)"
        />
        <path
          opacity="0.5"
          d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1574 8.68118 53.1574 16.724 48.1163 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
          fill="url(#paint1_linear_16_154)"
        />
        <path
          d="M10.7793 14.6041C11.0092 12.7148 12.6132 11.2942 14.5165 11.2942H48.9663C50.8506 11.2942 52.4446 12.6872 52.6971 14.5545L57.3056 48.6398C57.6111 50.8989 55.8545 52.909 53.5749 52.909H10.3687C8.10921 52.909 6.35867 50.9325 6.63161 48.6895L10.7793 14.6041Z"
          fill="#FF9371"
        />
        <path
          d="M23.2945 11.2942L21.4121 13.1765L22.285 14.665L25.6474 11.2942H23.2945Z"
          fill="#D3542B"
        />
        <path
          d="M34.1177 11.2942L32.2354 13.1765L33.1082 14.665L36.4706 11.2942H34.1177Z"
          fill="#D3542B"
        />
        <path
          d="M44.4712 11.2942L42.5889 13.1765L43.4618 14.665L46.8242 11.2942H44.4712Z"
          fill="#D3542B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1763 16.6118H16.674V15.3883H15.049C14.4946 15.3883 14.0299 15.8072 13.9725 16.3586L10.6865 47.9368L11.9035 48.0634L15.1763 16.6118Z"
          fill="#FFB7A0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.355 16.6118H46.8572V15.3883H48.4822C49.0366 15.3883 49.5014 15.8072 49.5588 16.3586L52.8447 47.9368L51.6278 48.0634L48.355 16.6118Z"
          fill="#FFB7A0"
        />
        <path
          d="M21.4118 7.52948C22.0616 7.52948 22.5883 8.0562 22.5883 8.70595L22.5883 13.8824C22.5883 14.5322 22.0616 15.0589 21.4118 15.0589C20.7621 15.0589 20.2354 14.5322 20.2354 13.8824L20.2354 8.70595C20.2354 8.0562 20.7621 7.52948 21.4118 7.52948Z"
          fill="#FE754A"
        />
        <rect
          x="20.2354"
          y="13.6472"
          width="0.941177"
          height="2.35294"
          transform="rotate(-90 20.2354 13.6472)"
          fill="#FFB49C"
        />
        <rect
          x="20.2354"
          y="11.7649"
          width="0.941177"
          height="2.35294"
          transform="rotate(-90 20.2354 11.7649)"
          fill="#FFB49C"
        />
        <rect
          x="20.2354"
          y="9.88251"
          width="0.941177"
          height="2.35294"
          transform="rotate(-90 20.2354 9.88251)"
          fill="#FFB49C"
        />
        <path
          d="M32.236 7.52948C32.8858 7.52948 33.4125 8.0562 33.4125 8.70595L33.4125 13.8824C33.4125 14.5322 32.8858 15.0589 32.236 15.0589C31.5863 15.0589 31.0596 14.5322 31.0596 13.8824L31.0596 8.70595C31.0596 8.0562 31.5863 7.52948 32.236 7.52948Z"
          fill="#FE754A"
        />
        <rect
          x="31.0596"
          y="13.6472"
          width="0.941177"
          height="2.35294"
          transform="rotate(-90 31.0596 13.6472)"
          fill="#FFB49C"
        />
        <rect
          x="31.0596"
          y="11.7649"
          width="0.941177"
          height="2.35294"
          transform="rotate(-90 31.0596 11.7649)"
          fill="#FFB49C"
        />
        <rect
          x="31.0596"
          y="9.88251"
          width="0.941177"
          height="2.35294"
          transform="rotate(-90 31.0596 9.88251)"
          fill="#FFB49C"
        />
        <path
          d="M42.5886 7.52948C43.2383 7.52948 43.7651 8.0562 43.7651 8.70595L43.7651 13.8824C43.7651 14.5322 43.2383 15.0589 42.5886 15.0589C41.9388 15.0589 41.4121 14.5322 41.4121 13.8824L41.4121 8.70595C41.4121 8.0562 41.9388 7.52948 42.5886 7.52948Z"
          fill="#FE754A"
        />
        <rect
          x="41.4121"
          y="13.6472"
          width="0.941177"
          height="2.35294"
          transform="rotate(-90 41.4121 13.6472)"
          fill="#FFB49C"
        />
        <rect
          x="41.4121"
          y="11.7649"
          width="0.941177"
          height="2.35294"
          transform="rotate(-90 41.4121 11.7649)"
          fill="#FFB49C"
        />
        <rect
          x="41.4121"
          y="9.88251"
          width="0.941177"
          height="2.35294"
          transform="rotate(-90 41.4121 9.88251)"
          fill="#FFB49C"
        />
        <path
          d="M33.3317 29.3271C33.3317 29.9496 33.2163 30.4766 32.9854 30.9083C32.7545 31.33 32.4432 31.6713 32.0517 31.9323C31.6602 32.1933 31.2084 32.3841 30.6964 32.5045C30.1844 32.625 29.6523 32.6853 29.1002 32.6853H27.8202V36.706H25.2451V26.0443H29.1604C29.7427 26.0443 30.2848 26.1045 30.7868 26.225C31.2988 26.3354 31.7405 26.5212 32.1119 26.7822C32.4934 27.0332 32.7896 27.3695 33.0004 27.7911C33.2213 28.2027 33.3317 28.7147 33.3317 29.3271ZM30.7566 29.3422C30.7566 29.0912 30.7065 28.8854 30.6061 28.7248C30.5057 28.5642 30.3701 28.4387 30.1995 28.3483C30.0288 28.258 29.833 28.1977 29.6122 28.1676C29.4014 28.1375 29.1805 28.1224 28.9496 28.1224H27.8202V30.6373H28.9044C29.1454 30.6373 29.3763 30.6172 29.5971 30.577C29.818 30.5369 30.0137 30.4666 30.1844 30.3662C30.3651 30.2658 30.5057 30.1353 30.6061 29.9747C30.7065 29.804 30.7566 29.5932 30.7566 29.3422Z"
          fill="white"
        />
        <path
          d="M39.7118 31.2245C39.6014 31.1944 39.491 31.1744 39.3805 31.1643C39.2801 31.1543 39.1797 31.1493 39.0794 31.1493C38.7481 31.1493 38.467 31.2095 38.2361 31.33C38.0152 31.4504 37.8345 31.596 37.6939 31.7667C37.5634 31.9373 37.4681 32.1231 37.4078 32.3238C37.3476 32.5246 37.3175 32.7003 37.3175 32.8509V36.706H34.8478V29.2067H37.2271V30.2909H37.2572C37.448 29.9094 37.719 29.5982 38.0704 29.3573C38.4218 29.1163 38.8284 28.9958 39.2902 28.9958C39.3906 28.9958 39.4859 29.0009 39.5763 29.0109C39.6767 29.0209 39.757 29.036 39.8172 29.0561L39.7118 31.2245Z"
          fill="white"
        />
        <circle cx="52" cy="12" r="12" fill={theme === "dark" ? "white" : "black"} />
        <path
          d="M53.4225 7.43359C54.2145 6.93306 55.2428 7.5268 55.2053 8.46289C55.1642 9.48994 55.7179 10.449 56.6279 10.9268C57.4573 11.3624 57.4573 12.5499 56.6279 12.9854C55.7179 13.4633 55.1642 14.4223 55.2053 15.4494C55.2428 16.3855 54.2145 16.9792 53.4225 16.4787C52.5537 15.9295 51.4463 15.9295 50.5774 16.4787C49.7855 16.9792 48.7571 16.3855 48.7946 15.4494C48.8358 14.4223 48.2821 13.4633 47.372 12.9854C46.5426 12.5499 46.5426 11.3624 47.372 10.9268C48.2821 10.449 48.8358 9.48994 48.7946 8.46289C48.7571 7.5268 49.7855 6.93306 50.5774 7.43359C51.4463 7.98276 52.5537 7.98276 53.4225 7.43359Z"
          stroke={theme === "dark" ? "black" : "white"}
          strokeWidth="1.5"
        />
        <circle cx="52" cy="12" r="1" fill={theme === "dark" ? "black" : "white"} />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_16_154"
          x1="15.8838"
          y1="60.2794"
          x2="59.5055"
          y2="15.9497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9371" />
          <stop offset="1" stopColor="#F26E44" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16_154"
          x1="48.1162"
          y1="3.72065"
          x2="4.49449"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9371" />
          <stop offset="1" stopColor="#F26E44" stopOpacity="0.2" />
        </linearGradient>
        <clipPath id="clip0_16_154">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
