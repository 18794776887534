export const SmartCity = ({ size = 64, theme }) =>
  theme === "light" ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M41.9634 16.6522C47.0046 11.6915 55.1779 11.6915 60.2191 16.6522C65.2603 21.6128 65.2603 29.6557 60.2191 34.6164L34.1395 60.2795C29.0983 65.2402 20.9249 65.2402 15.8837 60.2795C10.8426 55.3188 10.8426 47.276 15.8837 42.3153L41.9634 16.6522Z"
        fill="url(#paint0_linear_3_117)"
      />
      <path
        opacity="0.3"
        d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1574 8.68118 53.1574 16.724 48.1163 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
        fill="url(#paint1_linear_3_117)"
      />
      <path
        d="M33 19C33 16.7909 34.7909 15 37 15H54C56.2091 15 58 16.7909 58 19V50C58 51.1046 57.1046 52 56 52H33V19Z"
        fill="#FF9DCC"
      />
      <path
        d="M14 13C14 11.8954 14.8954 11 16 11H39C40.1046 11 41 11.8954 41 13V52H16C14.8954 52 14 51.1046 14 50V13Z"
        fill="#FF62AD"
      />
      <path
        d="M22 22C22 20.8954 22.8954 20 24 20H46C47.1046 20 48 20.8954 48 22V52H22V22Z"
        fill="#FD74B6"
      />
      <path
        d="M33.5314 30.664C33.3261 30.4027 33.0554 30.1973 32.7194 30.048C32.3927 29.8893 32.0754 29.81 31.7674 29.81C31.6087 29.81 31.4454 29.824 31.2774 29.852C31.1187 29.88 30.9741 29.936 30.8434 30.02C30.7127 30.0947 30.6007 30.1973 30.5074 30.328C30.4234 30.4493 30.3814 30.608 30.3814 30.804C30.3814 30.972 30.4141 31.112 30.4794 31.224C30.5541 31.336 30.6567 31.434 30.7874 31.518C30.9274 31.602 31.0907 31.6813 31.2774 31.756C31.4641 31.8213 31.6741 31.8913 31.9074 31.966C32.2434 32.078 32.5934 32.204 32.9574 32.344C33.3214 32.4747 33.6527 32.652 33.9514 32.876C34.2501 33.1 34.4974 33.38 34.6934 33.716C34.8894 34.0427 34.9874 34.4533 34.9874 34.948C34.9874 35.5173 34.8801 36.012 34.6654 36.432C34.4601 36.8427 34.1801 37.1833 33.8254 37.454C33.4707 37.7247 33.0647 37.9253 32.6074 38.056C32.1501 38.1867 31.6787 38.252 31.1934 38.252C30.4841 38.252 29.7981 38.1307 29.1354 37.888C28.4727 37.636 27.9221 37.2813 27.4834 36.824L29.0514 35.228C29.2941 35.5267 29.6114 35.7787 30.0034 35.984C30.4047 36.18 30.8014 36.278 31.1934 36.278C31.3707 36.278 31.5434 36.2593 31.7114 36.222C31.8794 36.1847 32.0241 36.124 32.1454 36.04C32.2761 35.956 32.3787 35.844 32.4534 35.704C32.5281 35.564 32.5654 35.396 32.5654 35.2C32.5654 35.0133 32.5187 34.8547 32.4254 34.724C32.3321 34.5933 32.1967 34.4767 32.0194 34.374C31.8514 34.262 31.6367 34.164 31.3754 34.08C31.1234 33.9867 30.8341 33.8887 30.5074 33.786C30.1901 33.6833 29.8774 33.562 29.5694 33.422C29.2707 33.282 29.0001 33.1047 28.7574 32.89C28.5241 32.666 28.3327 32.4 28.1834 32.092C28.0434 31.7747 27.9734 31.392 27.9734 30.944C27.9734 30.3933 28.0854 29.922 28.3094 29.53C28.5334 29.138 28.8274 28.816 29.1914 28.564C29.5554 28.312 29.9661 28.13 30.4234 28.018C30.8807 27.8967 31.3427 27.836 31.8094 27.836C32.3694 27.836 32.9387 27.9387 33.5174 28.144C34.1054 28.3493 34.6187 28.6527 35.0574 29.054L33.5314 30.664Z"
        fill="white"
      />
      <path
        d="M41.3829 33.282C41.2522 33.114 41.0749 32.9787 40.8509 32.876C40.6269 32.7733 40.3982 32.722 40.1649 32.722C39.9222 32.722 39.7029 32.7733 39.5069 32.876C39.3109 32.9693 39.1429 33.1 39.0029 33.268C38.8629 33.4267 38.7509 33.6133 38.6669 33.828C38.5922 34.0427 38.5549 34.2713 38.5549 34.514C38.5549 34.7567 38.5922 34.9853 38.6669 35.2C38.7416 35.4147 38.8489 35.606 38.9889 35.774C39.1382 35.9327 39.3109 36.0587 39.5069 36.152C39.7122 36.2453 39.9409 36.292 40.1929 36.292C40.4262 36.292 40.6549 36.25 40.8789 36.166C41.1122 36.0727 41.2989 35.942 41.4389 35.774L42.7129 37.328C42.4236 37.608 42.0502 37.8273 41.5929 37.986C41.1355 38.1447 40.6502 38.224 40.1369 38.224C39.5862 38.224 39.0729 38.14 38.5969 37.972C38.1209 37.804 37.7056 37.5613 37.3509 37.244C37.0056 36.9173 36.7302 36.5253 36.5249 36.068C36.3289 35.6107 36.2309 35.0927 36.2309 34.514C36.2309 33.9447 36.3289 33.4313 36.5249 32.974C36.7302 32.5167 37.0056 32.1293 37.3509 31.812C37.7056 31.4853 38.1209 31.238 38.5969 31.07C39.0729 30.8927 39.5815 30.804 40.1229 30.804C40.3749 30.804 40.6222 30.8273 40.8649 30.874C41.1169 30.9207 41.3549 30.986 41.5789 31.07C41.8122 31.1447 42.0222 31.2427 42.2089 31.364C42.4049 31.476 42.5729 31.602 42.7129 31.742L41.3829 33.282Z"
        fill="white"
      />
      <path d="M22 52V22L19 52H22Z" fill="#FF2F93" />

      <defs>
        <linearGradient
          id="paint0_linear_3_117"
          x1="15.8838"
          y1="60.2794"
          x2="59.5055"
          y2="15.9497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF1D89" />
          <stop offset="1" stopColor="#FFE0EF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_117"
          x1="48.1162"
          y1="3.72065"
          x2="4.4945"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF1D89" />
          <stop offset="1" stopColor="#FFE0EF" />
        </linearGradient>
        <clipPath id="clip0_3_117">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <g clipPath="url(#clip0_3_245)"> */}
      <path
        opacity="0.3"
        d="M41.9634 16.6522C47.0046 11.6915 55.1779 11.6915 60.2191 16.6522C65.2603 21.6128 65.2603 29.6557 60.2191 34.6164L34.1395 60.2795C29.0983 65.2402 20.9249 65.2402 15.8837 60.2795C10.8426 55.3188 10.8426 47.276 15.8837 42.3153L41.9634 16.6522Z"
        fill="url(#paint0_linear_3_245)"
      />
      <path
        opacity="0.3"
        d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1574 8.68118 53.1574 16.724 48.1163 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
        fill="url(#paint1_linear_3_245)"
      />
      <path
        d="M33 19C33 16.7909 34.7909 15 37 15H54C56.2091 15 58 16.7909 58 19V50C58 51.1046 57.1046 52 56 52H33V19Z"
        fill="#FF9DCC"
      />
      <path
        d="M14 13C14 11.8954 14.8954 11 16 11H39C40.1046 11 41 11.8954 41 13V52H16C14.8954 52 14 51.1046 14 50V13Z"
        fill="#FF62AD"
      />
      <path
        d="M22 22C22 20.8954 22.8954 20 24 20H46C47.1046 20 48 20.8954 48 22V52H22V22Z"
        fill="#FD74B6"
      />
      <path
        d="M33.5314 30.664C33.3261 30.4027 33.0554 30.1973 32.7194 30.048C32.3927 29.8893 32.0754 29.81 31.7674 29.81C31.6087 29.81 31.4454 29.824 31.2774 29.852C31.1187 29.88 30.9741 29.936 30.8434 30.02C30.7127 30.0947 30.6007 30.1973 30.5074 30.328C30.4234 30.4493 30.3814 30.608 30.3814 30.804C30.3814 30.972 30.4141 31.112 30.4794 31.224C30.5541 31.336 30.6567 31.434 30.7874 31.518C30.9274 31.602 31.0907 31.6813 31.2774 31.756C31.4641 31.8213 31.6741 31.8913 31.9074 31.966C32.2434 32.078 32.5934 32.204 32.9574 32.344C33.3214 32.4747 33.6527 32.652 33.9514 32.876C34.2501 33.1 34.4974 33.38 34.6934 33.716C34.8894 34.0427 34.9874 34.4533 34.9874 34.948C34.9874 35.5173 34.8801 36.012 34.6654 36.432C34.4601 36.8427 34.1801 37.1833 33.8254 37.454C33.4707 37.7247 33.0647 37.9253 32.6074 38.056C32.1501 38.1867 31.6787 38.252 31.1934 38.252C30.4841 38.252 29.7981 38.1307 29.1354 37.888C28.4727 37.636 27.9221 37.2813 27.4834 36.824L29.0514 35.228C29.2941 35.5267 29.6114 35.7787 30.0034 35.984C30.4047 36.18 30.8014 36.278 31.1934 36.278C31.3707 36.278 31.5434 36.2593 31.7114 36.222C31.8794 36.1847 32.0241 36.124 32.1454 36.04C32.2761 35.956 32.3787 35.844 32.4534 35.704C32.5281 35.564 32.5654 35.396 32.5654 35.2C32.5654 35.0133 32.5187 34.8547 32.4254 34.724C32.3321 34.5933 32.1967 34.4767 32.0194 34.374C31.8514 34.262 31.6367 34.164 31.3754 34.08C31.1234 33.9867 30.8341 33.8887 30.5074 33.786C30.1901 33.6833 29.8774 33.562 29.5694 33.422C29.2707 33.282 29.0001 33.1047 28.7574 32.89C28.5241 32.666 28.3327 32.4 28.1834 32.092C28.0434 31.7747 27.9734 31.392 27.9734 30.944C27.9734 30.3933 28.0854 29.922 28.3094 29.53C28.5334 29.138 28.8274 28.816 29.1914 28.564C29.5554 28.312 29.9661 28.13 30.4234 28.018C30.8807 27.8967 31.3427 27.836 31.8094 27.836C32.3694 27.836 32.9387 27.9387 33.5174 28.144C34.1054 28.3493 34.6187 28.6527 35.0574 29.054L33.5314 30.664Z"
        fill="white"
      />
      <path
        d="M41.3829 33.282C41.2522 33.114 41.0749 32.9787 40.8509 32.876C40.6269 32.7733 40.3982 32.722 40.1649 32.722C39.9222 32.722 39.7029 32.7733 39.5069 32.876C39.3109 32.9693 39.1429 33.1 39.0029 33.268C38.8629 33.4267 38.7509 33.6133 38.6669 33.828C38.5922 34.0427 38.5549 34.2713 38.5549 34.514C38.5549 34.7567 38.5922 34.9853 38.6669 35.2C38.7416 35.4147 38.8489 35.606 38.9889 35.774C39.1382 35.9327 39.3109 36.0587 39.5069 36.152C39.7122 36.2453 39.9409 36.292 40.1929 36.292C40.4262 36.292 40.6549 36.25 40.8789 36.166C41.1122 36.0727 41.2989 35.942 41.4389 35.774L42.7129 37.328C42.4236 37.608 42.0502 37.8273 41.5929 37.986C41.1355 38.1447 40.6502 38.224 40.1369 38.224C39.5862 38.224 39.0729 38.14 38.5969 37.972C38.1209 37.804 37.7056 37.5613 37.3509 37.244C37.0056 36.9173 36.7302 36.5253 36.5249 36.068C36.3289 35.6107 36.2309 35.0927 36.2309 34.514C36.2309 33.9447 36.3289 33.4313 36.5249 32.974C36.7302 32.5167 37.0056 32.1293 37.3509 31.812C37.7056 31.4853 38.1209 31.238 38.5969 31.07C39.0729 30.8927 39.5815 30.804 40.1229 30.804C40.3749 30.804 40.6222 30.8273 40.8649 30.874C41.1169 30.9207 41.3549 30.986 41.5789 31.07C41.8122 31.1447 42.0222 31.2427 42.2089 31.364C42.4049 31.476 42.5729 31.602 42.7129 31.742L41.3829 33.282Z"
        fill="white"
      />
      <path d="M22 52V22L19 52H22Z" fill="#FF2F93" />
      {/* </g> */}
      <defs>
        <linearGradient
          id="paint0_linear_3_245"
          x1="15.8838"
          y1="60.2794"
          x2="59.5055"
          y2="15.9497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9DCC" />
          <stop offset="1" stopColor="#FF1D89" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_245"
          x1="48.1162"
          y1="3.72065"
          x2="4.4945"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9DCC" />
          <stop offset="1" stopColor="#FF1D89" stopOpacity="0.2" />
        </linearGradient>
        <clipPath id="clip0_3_245">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
