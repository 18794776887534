export const VisitorsSettings = ({ size = 64, theme }) =>
  theme === "light" ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M41.9634 16.6522C47.0046 11.6915 55.1779 11.6915 60.2191 16.6522C65.2603 21.6128 65.2603 29.6557 60.2191 34.6164L34.1395 60.2795C29.0983 65.2402 20.9249 65.2402 15.8837 60.2795C10.8426 55.3188 10.8426 47.276 15.8837 42.3153L41.9634 16.6522Z"
        fill="url(#paint0_linear_16_40)"
      />
      <path
        opacity="0.35"
        d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1574 8.68118 53.1574 16.724 48.1163 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
        fill="url(#paint1_linear_16_40)"
      />
      <path
        d="M8.94141 17.8823C8.94141 14.7636 11.4697 12.2353 14.5885 12.2353H49.412C52.5308 12.2353 55.0591 14.7636 55.0591 17.8823V46.1176C55.0591 49.2364 52.5308 51.7647 49.412 51.7647H14.5885C11.4697 51.7647 8.94141 49.2364 8.94141 46.1176V17.8823Z"
        fill="#0255FF"
      />
      <path
        d="M8.94141 17.8823C8.94141 14.7636 11.4697 12.2353 14.5885 12.2353H49.412C52.5308 12.2353 55.059 14.7636 55.059 17.8823V28.7059H8.94141V17.8823Z"
        fill="#2E72FF"
      />
      <path
        d="M19.7652 7.05878C20.8048 7.05878 21.6475 7.90154 21.6475 8.94113L21.6475 16.4705C21.6475 17.5101 20.8048 18.3529 19.7652 18.3529C18.7256 18.3529 17.8828 17.5101 17.8828 16.4705L17.8828 8.94113C17.8828 7.90154 18.7256 7.05878 19.7652 7.05878Z"
        fill="#004DEC"
      />
      <path
        d="M44.7065 7.05878C45.7461 7.05878 46.5889 7.90154 46.5889 8.94113L46.5889 16.4705C46.5889 17.5101 45.7461 18.3529 44.7065 18.3529C43.6669 18.3529 42.8242 17.5101 42.8242 16.4705L42.8242 8.94113C42.8242 7.90154 43.6669 7.05878 44.7065 7.05878Z"
        fill="#004DEC"
      />
      <path
        d="M19.7651 5.64703C20.8047 5.64703 21.6475 6.48979 21.6475 7.52939L21.6475 15.0588C21.6475 16.0984 20.8047 16.9412 19.7651 16.9412C18.7255 16.9412 17.8828 16.0984 17.8828 15.0588L17.8828 7.52939C17.8828 6.48979 18.7255 5.64703 19.7651 5.64703Z"
        fill="#568DFF"
      />
      <path
        d="M44.7065 5.64703C45.7461 5.64703 46.5889 6.48979 46.5889 7.52939L46.5889 15.0588C46.5889 16.0984 45.7461 16.9412 44.7065 16.9412C43.6669 16.9412 42.8242 16.0984 42.8242 15.0588L42.8242 7.52939C42.8242 6.48979 43.6669 5.64703 44.7065 5.64703Z"
        fill="#568DFF"
      />
      <path
        d="M29.0893 36.7059H26.5143L22.4785 26.0443H25.3698L27.8395 33.6038H27.8997L30.3543 26.0443H33.2004L29.0893 36.7059Z"
        fill="white"
      />
      <path
        d="M39.098 31.4203C38.8973 31.2295 38.6613 31.0689 38.3903 30.9384C38.1192 30.8079 37.8331 30.7426 37.5319 30.7426C37.301 30.7426 37.0852 30.7878 36.8844 30.8781C36.6937 30.9685 36.5983 31.1241 36.5983 31.345C36.5983 31.5558 36.7037 31.7064 36.9145 31.7967C37.1354 31.8871 37.4868 31.9875 37.9686 32.0979C38.2497 32.1581 38.5308 32.2435 38.8119 32.3539C39.1031 32.4643 39.3641 32.6099 39.595 32.7906C39.8259 32.9713 40.0116 33.1922 40.1522 33.4532C40.2927 33.7142 40.363 34.0254 40.363 34.3868C40.363 34.8587 40.2676 35.2603 40.0769 35.5915C39.8861 35.9128 39.6351 36.1738 39.3239 36.3746C39.0228 36.5754 38.6814 36.721 38.2999 36.8113C37.9184 36.9017 37.5369 36.9468 37.1555 36.9468C36.5431 36.9468 35.9407 36.8515 35.3484 36.6607C34.7661 36.4599 34.2792 36.1688 33.8877 35.7873L35.2731 34.3266C35.494 34.5675 35.765 34.7683 36.0863 34.929C36.4075 35.0896 36.7489 35.1699 37.1103 35.1699C37.3111 35.1699 37.5068 35.1247 37.6976 35.0344C37.8984 34.934 37.9988 34.7633 37.9988 34.5224C37.9988 34.2915 37.8783 34.1208 37.6373 34.0104C37.3964 33.8999 37.0199 33.7845 36.5079 33.664C36.2469 33.6038 35.9859 33.5235 35.7249 33.4231C35.4638 33.3227 35.2279 33.1872 35.0171 33.0165C34.8163 32.8458 34.6507 32.635 34.5202 32.384C34.3897 32.133 34.3244 31.8319 34.3244 31.4805C34.3244 31.0287 34.4198 30.6472 34.6105 30.336C34.8013 30.0148 35.0472 29.7537 35.3484 29.553C35.6496 29.3522 35.9809 29.2066 36.3423 29.1163C36.7137 29.0159 37.0802 28.9657 37.4416 28.9657C38.0038 28.9657 38.5509 29.056 39.083 29.2367C39.6251 29.4074 40.0819 29.6684 40.4533 30.0198L39.098 31.4203Z"
        fill="white"
      />
      <circle cx="52" cy="12" r="12" fill="black" />
      <path
        d="M53.4225 7.43359C54.2145 6.93306 55.2428 7.5268 55.2053 8.46289C55.1642 9.48994 55.7179 10.449 56.6279 10.9268C57.4573 11.3624 57.4573 12.5499 56.6279 12.9854C55.7179 13.4633 55.1642 14.4223 55.2053 15.4494C55.2428 16.3855 54.2145 16.9792 53.4225 16.4787C52.5537 15.9295 51.4463 15.9295 50.5774 16.4787C49.7855 16.9792 48.7571 16.3855 48.7946 15.4494C48.8358 14.4223 48.2821 13.4633 47.372 12.9854C46.5426 12.5499 46.5426 11.3624 47.372 10.9268C48.2821 10.449 48.8358 9.48994 48.7946 8.46289C48.7571 7.5268 49.7855 6.93306 50.5774 7.43359C51.4463 7.98276 52.5537 7.98276 53.4225 7.43359Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <circle cx="52" cy="12" r="1" fill="white" />

      <defs>
        <linearGradient
          id="paint0_linear_16_40"
          x1="15.8838"
          y1="60.2794"
          x2="59.5055"
          y2="15.9497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0255FF" />
          <stop offset="1" stopColor="#AEC9FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16_40"
          x1="48.1162"
          y1="3.72065"
          x2="4.49449"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0255FF" />
          <stop offset="1" stopColor="#AEC9FF" />
        </linearGradient>
        <clipPath id="clip0_16_40">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16_181)">
        <path
          opacity="0.9"
          d="M41.9634 16.6522C47.0046 11.6915 55.1779 11.6915 60.2191 16.6522C65.2603 21.6128 65.2603 29.6557 60.2191 34.6164L34.1395 60.2795C29.0983 65.2402 20.9249 65.2402 15.8837 60.2795C10.8426 55.3188 10.8426 47.276 15.8837 42.3153L41.9634 16.6522Z"
          fill="url(#paint0_linear_16_181)"
        />
        <path
          opacity="0.8"
          d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1574 8.68118 53.1574 16.724 48.1163 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
          fill="url(#paint1_linear_16_181)"
        />
        <path
          d="M8.94141 17.8823C8.94141 14.7636 11.4697 12.2353 14.5885 12.2353H49.412C52.5308 12.2353 55.0591 14.7636 55.0591 17.8823V46.1176C55.0591 49.2364 52.5308 51.7647 49.412 51.7647H14.5885C11.4697 51.7647 8.94141 49.2364 8.94141 46.1176V17.8823Z"
          fill="#0255FF"
        />
        <path
          d="M8.94141 17.8823C8.94141 14.7636 11.4697 12.2353 14.5885 12.2353H49.412C52.5308 12.2353 55.059 14.7636 55.059 17.8823V28.7059H8.94141V17.8823Z"
          fill="#2E72FF"
        />
        <path
          d="M19.7652 7.05878C20.8048 7.05878 21.6475 7.90154 21.6475 8.94113L21.6475 16.4705C21.6475 17.5101 20.8048 18.3529 19.7652 18.3529C18.7256 18.3529 17.8828 17.5101 17.8828 16.4705L17.8828 8.94113C17.8828 7.90154 18.7256 7.05878 19.7652 7.05878Z"
          fill="#004DEC"
        />
        <path
          d="M44.7065 7.05878C45.7461 7.05878 46.5889 7.90154 46.5889 8.94113L46.5889 16.4705C46.5889 17.5101 45.7461 18.3529 44.7065 18.3529C43.6669 18.3529 42.8242 17.5101 42.8242 16.4705L42.8242 8.94113C42.8242 7.90154 43.6669 7.05878 44.7065 7.05878Z"
          fill="#004DEC"
        />
        <path
          d="M19.7651 5.64703C20.8047 5.64703 21.6475 6.48979 21.6475 7.52939L21.6475 15.0588C21.6475 16.0984 20.8047 16.9412 19.7651 16.9412C18.7255 16.9412 17.8828 16.0984 17.8828 15.0588L17.8828 7.52939C17.8828 6.48979 18.7255 5.64703 19.7651 5.64703Z"
          fill="#568DFF"
        />
        <path
          d="M44.7065 5.64703C45.7461 5.64703 46.5889 6.48979 46.5889 7.52939L46.5889 15.0588C46.5889 16.0984 45.7461 16.9412 44.7065 16.9412C43.6669 16.9412 42.8242 16.0984 42.8242 15.0588L42.8242 7.52939C42.8242 6.48979 43.6669 5.64703 44.7065 5.64703Z"
          fill="#568DFF"
        />
        <path
          d="M29.0893 36.7059H26.5143L22.4785 26.0443H25.3698L27.8395 33.6038H27.8997L30.3543 26.0443H33.2004L29.0893 36.7059Z"
          fill="white"
        />
        <path
          d="M39.098 31.4203C38.8973 31.2295 38.6613 31.0689 38.3903 30.9384C38.1192 30.8079 37.8331 30.7426 37.5319 30.7426C37.301 30.7426 37.0852 30.7878 36.8844 30.8781C36.6937 30.9685 36.5983 31.1241 36.5983 31.345C36.5983 31.5558 36.7037 31.7064 36.9145 31.7967C37.1354 31.8871 37.4868 31.9875 37.9686 32.0979C38.2497 32.1581 38.5308 32.2435 38.8119 32.3539C39.1031 32.4643 39.3641 32.6099 39.595 32.7906C39.8259 32.9713 40.0116 33.1922 40.1522 33.4532C40.2927 33.7142 40.363 34.0254 40.363 34.3868C40.363 34.8587 40.2676 35.2603 40.0769 35.5915C39.8861 35.9128 39.6351 36.1738 39.3239 36.3746C39.0228 36.5754 38.6814 36.721 38.2999 36.8113C37.9184 36.9017 37.5369 36.9468 37.1555 36.9468C36.5431 36.9468 35.9407 36.8515 35.3484 36.6607C34.7661 36.4599 34.2792 36.1688 33.8877 35.7873L35.2731 34.3266C35.494 34.5675 35.765 34.7683 36.0863 34.929C36.4075 35.0896 36.7489 35.1699 37.1103 35.1699C37.3111 35.1699 37.5068 35.1247 37.6976 35.0344C37.8984 34.934 37.9988 34.7633 37.9988 34.5224C37.9988 34.2915 37.8783 34.1208 37.6373 34.0104C37.3964 33.8999 37.0199 33.7845 36.5079 33.664C36.2469 33.6038 35.9859 33.5235 35.7249 33.4231C35.4638 33.3227 35.2279 33.1872 35.0171 33.0165C34.8163 32.8458 34.6507 32.635 34.5202 32.384C34.3897 32.133 34.3244 31.8319 34.3244 31.4805C34.3244 31.0287 34.4198 30.6472 34.6105 30.336C34.8013 30.0148 35.0472 29.7537 35.3484 29.553C35.6496 29.3522 35.9809 29.2066 36.3423 29.1163C36.7137 29.0159 37.0802 28.9657 37.4416 28.9657C38.0038 28.9657 38.5509 29.056 39.083 29.2367C39.6251 29.4074 40.0819 29.6684 40.4533 30.0198L39.098 31.4203Z"
          fill="white"
        />
        <circle cx="52" cy="12" r="12" fill={theme === "dark" ? "white" : "black"} />
        <path
          d="M53.4226 7.43359C54.2145 6.93306 55.2429 7.5268 55.2054 8.46289C55.1642 9.48994 55.7179 10.449 56.6279 10.9268C57.4574 11.3624 57.4574 12.5499 56.6279 12.9854C55.7179 13.4633 55.1642 14.4223 55.2054 15.4494C55.2429 16.3855 54.2145 16.9792 53.4226 16.4787C52.5537 15.9295 51.4463 15.9295 50.5774 16.4787C49.7855 16.9792 48.7571 16.3855 48.7946 15.4494C48.8358 14.4223 48.2821 13.4633 47.3721 12.9854C46.5426 12.5499 46.5426 11.3624 47.3721 10.9268C48.2821 10.449 48.8358 9.48994 48.7946 8.46289C48.7571 7.5268 49.7855 6.93306 50.5774 7.43359C51.4463 7.98276 52.5537 7.98276 53.4226 7.43359Z"
          stroke={theme === "dark" ? "black" : "white"}
          strokeWidth="1.5"
        />
        <circle cx="52" cy="12" r="1" fill={theme === "dark" ? "black" : "white"} />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_16_181"
          x1="15.8838"
          y1="60.2794"
          x2="59.5055"
          y2="15.9497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0255FF" stopOpacity="0.7" />
          <stop offset="1" stopColor="#0255FF" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16_181"
          x1="48.1162"
          y1="3.72065"
          x2="4.49449"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0255FF" stopOpacity="0.9" />
          <stop offset="1" stopColor="#0255FF" stopOpacity="0.2" />
        </linearGradient>
        <clipPath id="clip0_16_181">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
