import { makeAutoObservable } from "mobx";

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  userOrganizationId: string = null;

  isUserABackOfficeAdmin: boolean = false;

  isOwner: boolean = false;

  setUserOrganizationId = (userOrganizationId: string) => {
    this.userOrganizationId = userOrganizationId;
  };

  setIsUserABackOfficeAdmin = (userOrganizationId: string) => {
    if (userOrganizationId === process.env.REACT_APP_SIGNALOS_ORG_TOKEN) {
      this.isUserABackOfficeAdmin = true;
    } else {
      this.isUserABackOfficeAdmin = false;
    }
  };

  setIsOwner = (isOwner: boolean) => {
    this.isOwner = isOwner;
  };
}

export default UserStore;
