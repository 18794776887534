export const Hid = ({ size = 64, theme }) =>
  theme === "light" ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <g clipPath="url(#clip0_3259_5004)"> */}
      <path
        opacity="0.4"
        d="M41.9634 16.6522C47.0046 11.6915 55.1779 11.6915 60.2191 16.6522C65.2603 21.6128 65.2603 29.6557 60.2191 34.6164L34.1395 60.2795C29.0983 65.2402 20.9249 65.2402 15.8837 60.2795C10.8426 55.3188 10.8426 47.276 15.8837 42.3153L41.9634 16.6522Z"
        fill="url(#paint0_linear_3259_5004)"
      />
      <path
        opacity="0.25"
        d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1574 8.68118 53.1574 16.724 48.1163 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
        fill="url(#paint1_linear_3259_5004)"
      />
      <path
        d="M8.94141 17.8824C8.94141 14.7636 11.4697 12.2353 14.5885 12.2353H49.412C52.5308 12.2353 55.0591 14.7636 55.0591 17.8824V46.1177C55.0591 49.2364 52.5308 51.7647 49.412 51.7647H14.5885C11.4697 51.7647 8.94141 49.2364 8.94141 46.1177V17.8824Z"
        fill="#1D3058"
      />
      <path
        d="M23.5293 14.1176C23.5293 13.078 24.3721 12.2353 25.4117 12.2353H38.5881C39.6277 12.2353 40.4705 13.078 40.4705 14.1176C40.4705 15.1572 39.6277 16 38.5881 16H25.4116C24.3721 16 23.5293 15.1572 23.5293 14.1176Z"
        fill="#121C31"
      />
      <path
        d="M24.4707 13.1764C24.4707 12.6566 24.8921 12.2353 25.4119 12.2353H38.5884C39.1081 12.2353 39.5295 12.6566 39.5295 13.1764C39.5295 13.6962 39.1081 14.1176 38.5884 14.1176H25.4119C24.8921 14.1176 24.4707 13.6962 24.4707 13.1764Z"
        fill="#C3ED87"
      />
      <path
        d="M14.1172 43.2941C14.1172 43.0342 14.3279 42.8235 14.5878 42.8235H49.4113C49.6712 42.8235 49.8819 43.0342 49.8819 43.2941V44.2352C49.8819 44.4951 49.6712 44.7058 49.4113 44.7058H14.5878C14.3279 44.7058 14.1172 44.4951 14.1172 44.2352V43.2941Z"
        fill="#2A447C"
      />
      <path
        d="M14.1172 19.7647C14.1172 19.5048 14.3279 19.2941 14.5878 19.2941H49.4113C49.6712 19.2941 49.8819 19.5048 49.8819 19.7647V20.7058C49.8819 20.9657 49.6712 21.1764 49.4113 21.1764H14.5878C14.3279 21.1764 14.1172 20.9657 14.1172 20.7058V19.7647Z"
        fill="#2A447C"
      />
      <rect
        x="33.8828"
        y="51.7646"
        width="5.64706"
        height="3.76471"
        transform="rotate(90 33.8828 51.7646)"
        fill="#2C3F66"
      />
      <path d="M33.8819 54.1176L30.1172 51.7647L33.8819 51.7647L33.8819 54.1176Z" fill="#121C31" />
      <path
        d="M27.0602 35.7647V32.4216H23.943V35.7647H22.0117V27.7684H23.943V30.7953H27.0602V27.7684H28.9915V35.7647H27.0602Z"
        fill="white"
      />
      <path d="M31.2014 35.7647V27.7684H33.144V35.7647H31.2014Z" fill="white" />
      <path
        d="M42.8271 31.744C42.8271 32.4442 42.6954 33.0503 42.4318 33.5623C42.1758 34.0668 41.8332 34.4847 41.4041 34.816C40.9824 35.1397 40.5043 35.3807 39.9697 35.5388C39.4351 35.6894 38.893 35.7647 38.3434 35.7647H35.3617V27.7684H38.253C38.8177 27.7684 39.3749 27.8362 39.9245 27.9717C40.4742 28.0997 40.9636 28.3181 41.3928 28.6268C41.822 28.928 42.1683 29.3346 42.4318 29.8465C42.6954 30.3585 42.8271 30.991 42.8271 31.744ZM40.8168 31.744C40.8168 31.2922 40.7415 30.9195 40.5909 30.6258C40.4478 30.3247 40.2521 30.0875 40.0036 29.9143C39.7627 29.7336 39.4841 29.6056 39.1678 29.5303C38.8591 29.455 38.5391 29.4174 38.2078 29.4174H37.2478V34.0931H38.1627C38.509 34.0931 38.8403 34.0555 39.1565 33.9802C39.4803 33.8974 39.7627 33.7656 40.0036 33.5849C40.2521 33.4042 40.4478 33.1633 40.5909 32.8621C40.7415 32.5609 40.8168 32.1882 40.8168 31.744Z"
        fill="white"
      />
      {/* </g> */}
      <defs>
        <linearGradient
          id="paint0_linear_3259_5004"
          x1="15.8838"
          y1="60.2794"
          x2="59.5055"
          y2="15.9497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A447C" />
          <stop offset="1" stopColor="#A2B3D8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3259_5004"
          x1="48.1162"
          y1="3.72065"
          x2="4.4945"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A447C" />
          <stop offset="1" stopColor="#A2B3D8" />
        </linearGradient>
        <clipPath id="clip0_3259_5004">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M41.9634 16.6522C47.0046 11.6915 55.1779 11.6915 60.2191 16.6522C65.2603 21.6128 65.2603 29.6557 60.2191 34.6164L34.1395 60.2795C29.0983 65.2402 20.9249 65.2402 15.8837 60.2795C10.8426 55.3188 10.8426 47.276 15.8837 42.3153L41.9634 16.6522Z"
        fill="url(#paint0_linear_3_192)"
      />
      <path
        opacity="0.8"
        d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1574 8.68118 53.1574 16.724 48.1163 21.6847L22.0366 47.3478C16.9954 52.3085 8.82207 52.3085 3.78089 47.3478C-1.2603 42.3872 -1.2603 34.3443 3.78089 29.3836L29.8605 3.72051Z"
        fill="url(#paint1_linear_3_192)"
      />
      <path
        d="M8.94141 17.8824C8.94141 14.7636 11.4697 12.2353 14.5885 12.2353H49.412C52.5308 12.2353 55.0591 14.7636 55.0591 17.8824V46.1177C55.0591 49.2364 52.5308 51.7647 49.412 51.7647H14.5885C11.4697 51.7647 8.94141 49.2364 8.94141 46.1177V17.8824Z"
        fill="#1D3058"
      />
      <path
        d="M23.5293 14.1176C23.5293 13.078 24.3721 12.2353 25.4117 12.2353H38.5881C39.6277 12.2353 40.4705 13.078 40.4705 14.1176C40.4705 15.1572 39.6277 16 38.5881 16H25.4116C24.3721 16 23.5293 15.1572 23.5293 14.1176Z"
        fill="#121C31"
      />
      <path
        d="M24.4707 13.1764C24.4707 12.6566 24.8921 12.2353 25.4119 12.2353H38.5884C39.1081 12.2353 39.5295 12.6566 39.5295 13.1764C39.5295 13.6962 39.1081 14.1176 38.5884 14.1176H25.4119C24.8921 14.1176 24.4707 13.6962 24.4707 13.1764Z"
        fill="#C3ED87"
      />
      <path
        d="M14.1172 43.2941C14.1172 43.0342 14.3279 42.8235 14.5878 42.8235H49.4113C49.6712 42.8235 49.8819 43.0342 49.8819 43.2941V44.2352C49.8819 44.4951 49.6712 44.7058 49.4113 44.7058H14.5878C14.3279 44.7058 14.1172 44.4951 14.1172 44.2352V43.2941Z"
        fill="#2A447C"
      />
      <path
        d="M14.1172 19.7647C14.1172 19.5048 14.3279 19.2941 14.5878 19.2941H49.4113C49.6712 19.2941 49.8819 19.5048 49.8819 19.7647V20.7058C49.8819 20.9657 49.6712 21.1764 49.4113 21.1764H14.5878C14.3279 21.1764 14.1172 20.9657 14.1172 20.7058V19.7647Z"
        fill="#2A447C"
      />
      <rect
        x="33.8828"
        y="51.7646"
        width="5.64706"
        height="3.76471"
        transform="rotate(90 33.8828 51.7646)"
        fill="#2C3F66"
      />
      <path d="M33.8819 54.1176L30.1172 51.7647L33.8819 51.7647L33.8819 54.1176Z" fill="#121C31" />
      <path
        d="M27.0602 35.7647V32.4216H23.943V35.7647H22.0117V27.7684H23.943V30.7953H27.0602V27.7684H28.9915V35.7647H27.0602Z"
        fill="white"
      />
      <path d="M31.2014 35.7647V27.7684H33.144V35.7647H31.2014Z" fill="white" />
      <path
        d="M42.8271 31.744C42.8271 32.4442 42.6954 33.0503 42.4318 33.5623C42.1758 34.0668 41.8332 34.4847 41.4041 34.816C40.9824 35.1397 40.5043 35.3807 39.9697 35.5388C39.4351 35.6894 38.893 35.7647 38.3434 35.7647H35.3617V27.7684H38.253C38.8177 27.7684 39.3749 27.8362 39.9245 27.9717C40.4742 28.0997 40.9636 28.3181 41.3928 28.6268C41.822 28.928 42.1683 29.3346 42.4318 29.8465C42.6954 30.3585 42.8271 30.991 42.8271 31.744ZM40.8168 31.744C40.8168 31.2922 40.7415 30.9195 40.5909 30.6258C40.4478 30.3247 40.2521 30.0875 40.0036 29.9143C39.7627 29.7336 39.4841 29.6056 39.1678 29.5303C38.8591 29.455 38.5391 29.4174 38.2078 29.4174H37.2478V34.0931H38.1627C38.509 34.0931 38.8403 34.0555 39.1565 33.9802C39.4803 33.8974 39.7627 33.7656 40.0036 33.5849C40.2521 33.4042 40.4478 33.1633 40.5909 32.8621C40.7415 32.5609 40.8168 32.1882 40.8168 31.744Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3_192"
          x1="15.8838"
          y1="60.2794"
          x2="59.5055"
          y2="15.9497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A447C" stopOpacity="0.9" />
          <stop offset="1" stopColor="#2A447C" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_192"
          x1="48.1162"
          y1="3.72065"
          x2="4.4945"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2A447C" stopOpacity="0.9" />
          <stop offset="1" stopColor="#2A447C" stopOpacity="0.2" />
        </linearGradient>
      </defs>
    </svg>
  );
