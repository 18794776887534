import AuthStore from "./AuthStore";
import UserStore from "./UserStore";

class ApplicationStore {
  initStores = () => {
    this.auth.init();
  };

  auth = new AuthStore();
  user = new UserStore();
}

export default new ApplicationStore();
