import "simplebar/dist/simplebar.min.css";
import "nprogress/nprogress.css";
import "react-image-lightbox/style.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";

import { SettingsProvider } from "./contexts/SettingsContext";
import { App } from "./app";
import ApplicationStoreProvider from "./contexts/ApplicationStoreContext";
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <Auth0Provider
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        redirectUri={window.location.origin}
        cacheLocation="localstorage"
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        useRefreshTokens={true}
      >
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ApplicationStoreProvider>
              <SettingsProvider>
                <App />
                <Toaster position="bottom-right" />
              </SettingsProvider>
            </ApplicationStoreProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </Auth0Provider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root")
);
