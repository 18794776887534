export const Signal = ({ size = 64, theme }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2042 4.26172H7.40928C5.67241 4.26172 4.22136 5.54695 3.93555 7.23804H14.7305C16.4674 7.23804 17.9184 5.95281 18.2042 4.26172Z"
      fill="white"
    />
    <path
      d="M14.7305 8.50098H3.93555C4.22136 10.2146 5.67241 11.4998 7.40928 11.4998H18.2042C17.9184 9.80876 16.4674 8.50098 14.7305 8.50098Z"
      fill="white"
    />
    <path
      d="M3.93543 7.23788H3.53969C1.58297 7.23788 0 5.61443 0 3.63021C0 1.62345 1.58297 0 3.53969 0H14.7304C16.6651 0 18.2481 1.62345 18.2481 3.63021C18.2481 3.85569 18.2261 4.05862 18.1821 4.26156H7.40916C5.6723 4.26156 4.22124 5.54679 3.93543 7.23788ZM3.93543 8.50056H3.53969C1.58297 8.50056 0 10.124 0 12.1308C0 14.1375 1.58297 15.761 3.53969 15.761H3.93543C4.22124 14.0699 5.6723 12.7847 7.40916 12.7847H18.2041C18.2481 12.5817 18.2701 12.3563 18.2701 12.1533C18.2701 11.9278 18.2481 11.7249 18.2041 11.522H7.40916C5.6723 11.4994 4.22124 10.2142 3.93543 8.50056ZM22.1176 7.86922C22.1176 5.86246 20.5346 4.23901 18.5779 4.23901H18.1821C17.8963 5.9301 16.4453 7.21533 14.7084 7.21533H3.93543C3.89146 7.41826 3.86947 7.64374 3.86947 7.84667C3.86947 8.07215 3.89146 8.27508 3.93543 8.47802H14.7304C16.4672 8.47802 17.9183 9.76325 18.2041 11.4543H18.5999C20.5566 11.4994 22.1176 9.87599 22.1176 7.86922ZM14.7304 15.761H3.93543C3.89146 15.9639 3.86947 16.1894 3.86947 16.3923C3.86947 18.3991 5.45244 20.0225 7.40916 20.0225H18.5999C20.5566 20.0225 22.1395 18.3991 22.1395 16.3923C22.1395 14.3856 20.5566 12.7621 18.5999 12.7621H18.2041C17.9183 14.4532 16.4672 15.761 14.7304 15.761Z"
      fill="url(#paint0_linear_620_2484)"
    />
    <path
      d="M3.93555 15.7608H14.7305C16.4674 15.7608 17.9184 14.4756 18.2042 12.7845H7.40928C5.67241 12.7619 4.22136 14.0472 3.93555 15.7608Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_620_2484"
        x1="-0.33904"
        y1="3.25143"
        x2="22.7511"
        y2="16.2501"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00113545" stopColor="#579EFF" />
        <stop offset="1" stopColor="#5764FF" />
      </linearGradient>
    </defs>
  </svg>
);
