export const AccessControlSettings = ({ size = 64, theme }) =>
  theme === "light" ? (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M41.9634 16.6523C47.0046 11.6916 55.178 11.6916 60.2192 16.6523C65.2604 21.613 65.2604 29.6558 60.2192 34.6165L34.1395 60.2797C29.0983 65.2404 20.925 65.2404 15.8838 60.2797C10.8426 55.319 10.8426 47.2761 15.8838 42.3155L41.9634 16.6523Z"
        fill="url(#paint0_linear_16_3)"
      />
      <path
        opacity="0.35"
        d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1575 8.68119 53.1575 16.724 48.1163 21.6847L22.0366 47.3479C16.9955 52.3086 8.82208 52.3086 3.78089 47.3479C-1.2603 42.3872 -1.2603 34.3444 3.78089 29.3837L29.8605 3.72051Z"
        fill="url(#paint1_linear_16_3)"
      />
      <path
        d="M2.62993 26.9917C1.4651 24.108 2.85372 20.8259 5.73152 19.6609L39.6011 5.94924C42.4789 4.7842 45.7561 6.17744 46.9209 9.06112L56.0604 31.687C57.2252 34.5707 55.8366 37.8528 52.9588 39.0179L19.0892 52.7295C16.2114 53.8945 12.9343 52.5013 11.7694 49.6176L2.62993 26.9917Z"
        fill="#2D70FB"
      />
      <path
        d="M6.24117 25.238C5.59573 22.1957 7.53413 19.2052 10.5707 18.5585L46.3091 10.9476C49.3457 10.301 52.3305 12.2431 52.976 15.2854L58.0403 39.1562C58.6857 42.1985 56.7473 45.1891 53.7107 45.8357L17.9724 53.4466C14.9358 54.0932 11.9509 52.1512 11.3055 49.1088L6.24117 25.238Z"
        fill="#578EFF"
      />
      <path
        d="M49.1358 21.0716C49.0284 20.5654 49.3517 20.0677 49.8578 19.9599C50.3639 19.8521 50.8612 20.1751 50.9686 20.6813L53.9883 34.9151C54.0957 35.4213 53.7725 35.919 53.2664 36.0268C52.7603 36.1345 52.263 35.8116 52.1556 35.3054L49.1358 21.0716Z"
        fill="#A5C2FF"
      />
      <path
        d="M30.679 36.7058L29.8508 34.6126H25.7246L24.9416 36.7058H22.1406L26.6131 26.0441H29.1129L33.5402 36.7058H30.679ZM27.8178 28.8902L26.4625 32.5495H29.143L27.8178 28.8902Z"
        fill="white"
      />
      <path
        d="M40.2039 31.631C40.0634 31.4503 39.8726 31.3047 39.6317 31.1943C39.3908 31.0838 39.1448 31.0286 38.8938 31.0286C38.6328 31.0286 38.3969 31.0838 38.1861 31.1943C37.9752 31.2946 37.7945 31.4352 37.6439 31.6159C37.4933 31.7866 37.3729 31.9874 37.2825 32.2183C37.2022 32.4492 37.1621 32.6951 37.1621 32.9561C37.1621 33.2172 37.2022 33.4631 37.2825 33.694C37.3628 33.9249 37.4783 34.1307 37.6289 34.3114C37.7895 34.4821 37.9752 34.6176 38.1861 34.718C38.4069 34.8184 38.6529 34.8686 38.9239 34.8686C39.1749 34.8686 39.4209 34.8234 39.6618 34.7331C39.9128 34.6327 40.1136 34.4921 40.2642 34.3114L41.6345 35.983C41.3233 36.2841 40.9217 36.5201 40.4298 36.6907C39.9379 36.8614 39.4159 36.9467 38.8637 36.9467C38.2714 36.9467 37.7192 36.8564 37.2072 36.6757C36.6952 36.495 36.2485 36.2339 35.867 35.8926C35.4955 35.5412 35.1994 35.1196 34.9785 34.6277C34.7677 34.1357 34.6623 33.5786 34.6623 32.9561C34.6623 32.3437 34.7677 31.7916 34.9785 31.2997C35.1994 30.8077 35.4955 30.3911 35.867 30.0498C36.2485 29.6984 36.6952 29.4324 37.2072 29.2517C37.7192 29.0609 38.2664 28.9655 38.8486 28.9655C39.1197 28.9655 39.3857 28.9906 39.6468 29.0408C39.9178 29.091 40.1738 29.1613 40.4148 29.2517C40.6657 29.332 40.8916 29.4374 41.0924 29.5679C41.3032 29.6884 41.4839 29.8239 41.6345 29.9745L40.2039 31.631Z"
        fill="white"
      />
      <circle cx="52" cy="12" r="12" fill="black" />
      <path
        d="M53.4226 7.43359C54.2145 6.93306 55.2429 7.5268 55.2054 8.46289C55.1642 9.48994 55.7179 10.449 56.6279 10.9268C57.4574 11.3624 57.4574 12.5499 56.6279 12.9854C55.7179 13.4633 55.1642 14.4223 55.2054 15.4494C55.2429 16.3855 54.2145 16.9792 53.4226 16.4787C52.5537 15.9295 51.4463 15.9295 50.5774 16.4787C49.7855 16.9792 48.7571 16.3855 48.7946 15.4494C48.8358 14.4223 48.2821 13.4633 47.3721 12.9854C46.5426 12.5499 46.5426 11.3624 47.3721 10.9268C48.2821 10.449 48.8358 9.48994 48.7946 8.46289C48.7571 7.5268 49.7855 6.93306 50.5774 7.43359C51.4463 7.98276 52.5537 7.98276 53.4226 7.43359Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <circle cx="52" cy="12" r="1" fill="white" />

      <defs>
        <linearGradient
          id="paint0_linear_16_3"
          x1="15.8838"
          y1="60.2795"
          x2="59.5056"
          y2="15.9499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D70FB" />
          <stop offset="1" stopColor="#AFC9FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16_3"
          x1="48.1162"
          y1="3.72066"
          x2="4.49449"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D70FB" />
          <stop offset="1" stopColor="#AFC9FF" />
        </linearGradient>
        <clipPath id="clip0_16_3">
          <rect width="64" height="64.0002" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16_144)">
        <path
          opacity="0.7"
          d="M41.9634 16.6523C47.0046 11.6916 55.178 11.6916 60.2192 16.6523C65.2604 21.613 65.2604 29.6558 60.2192 34.6165L34.1395 60.2797C29.0983 65.2404 20.925 65.2404 15.8838 60.2797C10.8426 55.319 10.8426 47.2761 15.8838 42.3155L41.9634 16.6523Z"
          fill="url(#paint0_linear_16_144)"
        />
        <path
          opacity="0.7"
          d="M29.8605 3.72051C34.9017 -1.24017 43.0751 -1.24017 48.1163 3.72051C53.1575 8.68119 53.1575 16.724 48.1163 21.6847L22.0366 47.3479C16.9955 52.3086 8.82208 52.3086 3.78089 47.3479C-1.2603 42.3872 -1.2603 34.3444 3.78089 29.3837L29.8605 3.72051Z"
          fill="url(#paint1_linear_16_144)"
        />
        <path
          d="M2.62993 26.9917C1.4651 24.108 2.85372 20.8259 5.73152 19.6609L39.6011 5.94924C42.4789 4.7842 45.7561 6.17744 46.9209 9.06112L56.0604 31.687C57.2252 34.5707 55.8366 37.8528 52.9588 39.0179L19.0892 52.7295C16.2114 53.8945 12.9343 52.5013 11.7694 49.6176L2.62993 26.9917Z"
          fill="#2D70FB"
        />
        <path
          d="M6.24117 25.238C5.59573 22.1957 7.53413 19.2052 10.5707 18.5585L46.3091 10.9476C49.3457 10.301 52.3305 12.2431 52.976 15.2854L58.0403 39.1562C58.6857 42.1985 56.7473 45.1891 53.7107 45.8357L17.9724 53.4466C14.9358 54.0932 11.9509 52.1512 11.3055 49.1088L6.24117 25.238Z"
          fill="#578EFF"
        />
        <path
          d="M49.1358 21.0716C49.0284 20.5654 49.3517 20.0677 49.8578 19.9599C50.3639 19.8521 50.8612 20.1751 50.9686 20.6813L53.9883 34.9151C54.0957 35.4213 53.7725 35.919 53.2664 36.0268C52.7603 36.1345 52.263 35.8116 52.1556 35.3054L49.1358 21.0716Z"
          fill="#A5C2FF"
        />
        <path
          d="M30.679 36.7058L29.8508 34.6126H25.7246L24.9416 36.7058H22.1406L26.6131 26.0441H29.1129L33.5402 36.7058H30.679ZM27.8178 28.8902L26.4625 32.5495H29.143L27.8178 28.8902Z"
          fill="white"
        />
        <path
          d="M40.204 31.631C40.0634 31.4503 39.8727 31.3047 39.6317 31.1943C39.3908 31.0838 39.1448 31.0286 38.8938 31.0286C38.6328 31.0286 38.3969 31.0838 38.1861 31.1943C37.9752 31.2946 37.7945 31.4352 37.644 31.6159C37.4934 31.7866 37.3729 31.9874 37.2825 32.2183C37.2022 32.4492 37.1621 32.6951 37.1621 32.9561C37.1621 33.2172 37.2022 33.4631 37.2825 33.694C37.3629 33.9249 37.4783 34.1307 37.6289 34.3114C37.7895 34.4821 37.9752 34.6176 38.1861 34.718C38.4069 34.8184 38.6529 34.8686 38.924 34.8686C39.1749 34.8686 39.4209 34.8234 39.6618 34.7331C39.9128 34.6327 40.1136 34.4921 40.2642 34.3114L41.6345 35.983C41.3233 36.2841 40.9218 36.5201 40.4298 36.6907C39.9379 36.8614 39.4159 36.9467 38.8637 36.9467C38.2714 36.9467 37.7192 36.8564 37.2072 36.6757C36.6952 36.495 36.2485 36.2339 35.867 35.8926C35.4956 35.5412 35.1994 35.1196 34.9785 34.6277C34.7677 34.1357 34.6623 33.5786 34.6623 32.9561C34.6623 32.3437 34.7677 31.7916 34.9785 31.2997C35.1994 30.8077 35.4956 30.3911 35.867 30.0498C36.2485 29.6984 36.6952 29.4324 37.2072 29.2517C37.7192 29.0609 38.2664 28.9655 38.8487 28.9655C39.1197 28.9655 39.3858 28.9906 39.6468 29.0408C39.9178 29.091 40.1738 29.1613 40.4148 29.2517C40.6658 29.332 40.8916 29.4374 41.0924 29.5679C41.3032 29.6884 41.484 29.8239 41.6345 29.9745L40.204 31.631Z"
          fill="white"
        />
        <circle cx="52" cy="12" r="12" fill={theme === "dark" ? "white" : "black"} />
        <path
          d="M53.4225 7.43359C54.2145 6.93306 55.2428 7.5268 55.2053 8.46289C55.1642 9.48994 55.7179 10.449 56.6279 10.9268C57.4573 11.3624 57.4573 12.5499 56.6279 12.9854C55.7179 13.4633 55.1642 14.4223 55.2053 15.4494C55.2428 16.3855 54.2145 16.9792 53.4225 16.4787C52.5537 15.9295 51.4463 15.9295 50.5774 16.4787C49.7855 16.9792 48.7571 16.3855 48.7946 15.4494C48.8358 14.4223 48.2821 13.4633 47.372 12.9854C46.5426 12.5499 46.5426 11.3624 47.372 10.9268C48.2821 10.449 48.8358 9.48994 48.7946 8.46289C48.7571 7.5268 49.7855 6.93306 50.5774 7.43359C51.4463 7.98276 52.5537 7.98276 53.4225 7.43359Z"
          stroke={theme === "dark" ? "black" : "white"}
          strokeWidth="1.5"
        />
        <circle cx="52" cy="12" r="1" fill={theme === "dark" ? "black" : "white"} />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_16_144"
          x1="15.8838"
          y1="60.2795"
          x2="59.5056"
          y2="15.9499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D70FB" stopOpacity="0.9" />
          <stop offset="1" stopColor="#2D70FB" stopOpacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16_144"
          x1="48.1162"
          y1="3.72066"
          x2="4.4945"
          y2="48.0503"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2D70FB" stopOpacity="0.9" />
          <stop offset="1" stopColor="#2D70FB" stopOpacity="0.2" />
        </linearGradient>
        <clipPath id="clip0_16_144">
          <rect width="64" height="64.0002" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
