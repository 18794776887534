import { useContext } from "react";

import { ApplicationStoreContext } from "../contexts/ApplicationStoreContext";
import { IApplicationStoreContext } from "../types/ApplicationStore";

const useStore = () => {
  const store: IApplicationStoreContext = useContext(ApplicationStoreContext);

  if (!store) {
    throw new Error("useStore must be used within a StoreProvider.");
  }

  return store;
};

export default useStore;
